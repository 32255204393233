import {
  faAngleLeft,
  faCar,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./TransferDetailScreen.css";
import qs from "qs";
import { getTransferDetail, postRequest } from "services";
import { END_POINTS } from "constants";
import moment from "moment";
import { setDriverLocation, setTransferDetailTime } from "@redux";
import { Alert } from "@mui/material";
import { db } from "services/firebaseConfig";
import { ref, update } from "firebase/database";
import { Toaster } from "react-hot-toast";

function TransferDetailScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const transferDetail = useSelector((state) => state.persisted.transferDetail);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const driverLocation = useSelector((state) => state.persisted.driverLocation);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const backArrow = (
    <FontAwesomeIcon icon={faAngleLeft} color="white" size={"lg"} />
  );
  const userIcon = <FontAwesomeIcon icon={faUser} color="grey" size={"1x"} />;
  const usersIcon = <FontAwesomeIcon icon={faUsers} color="grey" size={"1x"} />;
  const carIcon = <FontAwesomeIcon icon={faCar} color="grey" size={"1x"} />;

  useEffect(() => {
    getWatchPosition();
  }, []);

  function getWatchPosition() {
    console.log("watch run");
    navigator.geolocation.watchPosition((position) => {
      console.log(
        "watch position: ",
        "latitude: ",
        position.coords.latitude,
        "longitude: ",
        position.coords.longitude
      );
      if (position.coords.latitude !== 0 && position.coords.longitude !== 0) {
        dispatch(
          setDriverLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      }
      update(ref(db, "pudriver/drivers/" + driverInfo.id), {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        bearing: position.coords.heading,
        speed: position.coords.speed,
        time: position.timestamp,
        accuracy: position.coords.accuracy,
        plate: driverInfo.plate,
        update_time: moment().locale("tr").format("YYYY-MM-DD HH:mm:ss"),
      });
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }

  async function goPickUpScreen() {
    setSpinner(true);
    if (transferDetail.driver_status_id == 2) {
      var data = qs.stringify({
        id: transferDetail.id,
        status: transferDetail.driver_status_id,
        latitude: driverLocation.latitude,
        longitude: driverLocation.longitude,
      });
      try {
        const newTransferStatus = await postRequest(
          END_POINTS.CHANGE_TRANSFER_STATUS,
          data
        );
        if (newTransferStatus.status_code == 1) {
          console.log(
            "TransferDetailScreen - goPickUpScreen function - başarılı"
          );
          const time = moment();
          dispatch(setTransferDetailTime(time));
          getTransferDetail(transferDetail.id);
          setSpinner(false);
          navigate("/Yolcu-Alis-Noktasi");
        } else {
          setStatusCode(0);
          setStatusMessage(newTransferStatus.status_message);
          setSpinner(false);
          setTimeout(() => {
            setStatusCode(null);
          }, 2000);
          console.log(
            "TransferDetailScreen - goPickUpScreen function - başarısız"
          );
        }
      } catch (error) {
        console.log(
          "TransferDetailScreen - goPickUpScreen function - error: ",
          error
        );
      }
    } else if (transferDetail.driver_status_id > 2) {
      navigate("/Yolcu-Alis-Noktasi");
      setSpinner(false);
    } else {
      console.log(
        "TransferDetailScreen - goPickUpScreen function - transfer adımı hatası"
      );
      setSpinner(false);
    }
  }

  return (
    <div className="transfer-detail">
      <div className="transfer-detail-black-header d-flex align-items-center ps-3">
        <Toaster />
        <div onClick={() => navigate("/")}>
          {backArrow}
          <span className="text-white fw-semibold ms-2">Transfer Detayı</span>
        </div>
      </div>
      {statusCode == 0 ? (
        <Alert className="alert-class" severity="error">
          {statusMessage}
        </Alert>
      ) : (
        <></>
      )}
      <div className="transfer-detail-screen">
        <div
          className="d-flex justify-content-end fw-bold pe-1"
          style={{ fontSize: "x-small" }}
        >
          Rez. No: {transferDetail.transfer_no}
        </div>
        <div className="detail-first-box border d-flex align-items-center justify-content-around">
          <div className="d-flex align-items-center flex-column col-5">
            <span
              className="text-secondary fw-semibold"
              style={{ fontSize: "small" }}
            >
              {transferDetail.transfer_date}
            </span>
            <span className="text-body fw-bold fs-3">
              {transferDetail.transfer_hour}
            </span>
            <span className="text-body fw-bold fs-6">
              {transferDetail.price} {transferDetail.currency}
            </span>
            <span
              className="text-success fw-bold"
              style={{ fontSize: "x-small" }}
            >
              Kabul Edildi
            </span>
          </div>
          <div className="d-flex flex-column col-5">
            <div className="d-flex align-items-center">
              {userIcon}{" "}
              <span
                className="ms-1 fw-semibold"
                style={{ color: "black", fontSize: "smaller" }}
              >
                {transferDetail.passenger_name}
              </span>
            </div>
            <div className="d-flex align-items-center mt-1">
              {usersIcon}{" "}
              <span
                className="ms-1 fw-semibold"
                style={{ color: "black", fontSize: "smaller" }}
              >
                {transferDetail.number_of_person}
              </span>
            </div>
            <div className="d-flex align-items-center mt-1">
              {carIcon}{" "}
              <span
                className="ms-1 fw-semibold"
                style={{ color: "black", fontSize: "smaller" }}
              >
                {transferDetail.car_type}
              </span>
            </div>
          </div>
        </div>
        <div className="detail-second-box d-flex align-items-center">
          <button
            type="button"
            className="btn btn-primary detail-go-btn fs-4 fw-bold d-flex flex-column align-items-center justify-content-center"
            onClick={goPickUpScreen}
          >
            GİT
            {spinner && (
              <div className="clearfix">
                <div
                  className="spinner-border float-end mt-1"
                  style={{ width: "4.5vw", height: "4.5vw" }}
                  role="status"
                ></div>
              </div>
            )}
          </button>
          <div className="d-flex flex-column ps-3" style={{ width: "80vw" }}>
            <span className="text-secondary" style={{ fontSize: "small" }}>
              Yolcu Alış Konumu
            </span>
            <span
              className="mt-4 text-black fw-semibold"
              style={{ fontSize: "smaller" }}
            >
              {transferDetail.start_address}
            </span>
          </div>
        </div>
        <div className="detail-separator"></div>
        <div className="detail-third-box d-flex align-items-center">
          <button
            type="button"
            className="btn detail-go-btn fs-4 fw-bold"
          ></button>
          <div className="d-flex flex-column ps-3" style={{ width: "80vw" }}>
            <span className="text-secondary" style={{ fontSize: "small" }}>
              Nereye
            </span>
            <span
              className="mt-4 text-danger fw-semibold"
              style={{ fontSize: "smaller" }}
            >
              Yolcu alındıktan sonra bırakış adresini görüntüleyebilirsiniz.
            </span>
          </div>
        </div>
        <div className="detail-forth-box border ps-2">
          <div>
            <div>
              <span
                className="text-secondary fw-bold"
                style={{ fontSize: "x-small" }}
              >
                Yolcu Listesi:
              </span>
            </div>
            {transferDetail.passengers.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex flex-column mt-1 detail-passengers-list ps-5"
                >
                  <span>{item.namesurname}</span>
                </div>
              );
            })}
            <div
              className="mt-2"
              style={{ height: "12.25vh", fontSize: "small" }}
            >
              <span>
                <b className="me-1">Not:</b>
                {transferDetail.transfer_note}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferDetailScreen;
