import {
  faAngleLeft,
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fontSize } from "@mui/system";
import { greenPriceBg } from "assets";
import axios from "axios";
import { AgencyBottomBar, PoolBottomBar } from "components";
import { END_POINTS } from "constants";
import FlatList from "flatlist-react";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings/build/star-ratings";
import { getRequest } from "services";
import "./EarningScreen.css";

function EarningScreen() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.persisted.token);
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const group = useSelector((state) => state.persisted.group);
  const pool = useSelector((state) => state.persisted.pool);
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedPool, setSelectedPool] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(true);
  const [selectedTransfers, setSelectedTransfers] = useState(false);
  const [earnings, setEarnings] = useState([]);
  const [thisMonthTransfers, setThisMonthTransfers] = useState([]);
  const [loading, setLoading] = useState(true);

  const leftArrow = (
    <FontAwesomeIcon icon={faAngleLeft} color="black" size={"xl"} />
  );

  const greenArrow = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faCaretRight}
      color="green"
      size={"2x"}
    />
  );

  const greyArrow = (
    <FontAwesomeIcon
      className="card-grey-arrow"
      icon={faCaretDown}
      color="grey"
      size={"2x"}
    />
  );

  useEffect(() => {
    getEarnings();
    getThisMonthTransfers();
  }, []);

  async function getEarnings() {
    try {
      const driverEarnings = await getRequest(END_POINTS.EARNINGS);
      setEarnings(driverEarnings);
    } catch (error) {
      console.log("getDriverEarnings error: ", error);
    }
  }

  function getThisMonthTransfers() {
    const startOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().clone().endOf("month").format("YYYY-MM-DD");
    var config = {
      method: "get",
      url: `https://api.govip.com.tr/api/transfers?start_date=${startOfMonth}&end_date=${endOfMonth}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const filteredData = response.data.filter(function (f) {
          return f.driver_status_id == 6 || f.driver_status_id == 8;
        });
        setThisMonthTransfers(filteredData);
        setTimeout(() => {
          setLoading(false);
        }, 750);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function goPoolHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolPool() {
    navigate("/Havuz");
    setSelectedHome(false);
    setSelectedPool(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolProfile() {
    navigate("/Profil");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function goPoolNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }

  function goAgencyHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyTransfers() {
    navigate("/Transferlerim");
    setSelectedHome(false);
    setSelectedTransfers(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }
  function goAgencyProfile() {
    navigate("/Profilim");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }

  const RenderEarnings = (item) => {
    return (
      <div className="pt-2 pb-1 d-flex justify-content-center">
        <div className="home-render-container shadow-sm d-flex justify-content-center">
          <div
            className="home-render-status-bar"
            style={{ backgroundColor: item.item.driver_status_color_code }}
          ></div>
          <div className="home-render-transfer-card d-flex flex-column">
            <div className="home-render-card-top d-flex align-items-center justify-content-around">
              <div className="card-short-div">
                <span className="card-short-txt">
                  {item.item.short_start_address}
                </span>
              </div>
              <div>{greenArrow}</div>
              <div className="card-short-div">
                <span className="card-short-txt">
                  {item.item.short_end_address}
                </span>
              </div>
            </div>
            <div className="home-card-separator"></div>
            {pool ? (
              <div className="home-render-card-bottom d-flex align-items-center ">
                <div className="card-date-div d-flex flex-column align-items-center">
                  <span className="card-date-txt">
                    {item.item.transfer_date}
                  </span>
                  <span className="card-hour-txt">
                    {item.item.transfer_hour}
                  </span>
                </div>
                <div className="card-status-div d-flex flex-column align-items-center">
                  <span
                    className="card-status-txt"
                    style={{
                      color: item.item.driver_status_color_code,
                      fontSize: "small",
                    }}
                  >
                    {item.item.driver_status_title}
                  </span>
                  <div>{greyArrow}</div>
                </div>
                <div className="card-price-div d-flex flex-column align-items-center">
                  <span
                    className="payment-txt"
                    style={
                      item.item.pool_payment_status == "Ödeme Bekleniyor"
                        ? { color: "#dc8709" }
                        : item.item.pool_payment_status == "Askıya Alındı"
                        ? { color: "#C70B0A" }
                        : item.item.pool_payment_status == "Ödendi"
                        ? { color: "#47a716" }
                        : { color: "black" }
                    }
                  >
                    {item.item.pool_payment_status}
                  </span>
                  <img className="card-price-img" src={greenPriceBg} />
                  <span className="card-price-txt pt-3">
                    {item.item.price} {item.item.currency}
                  </span>
                </div>
              </div>
            ) : (
              <div className="home-render-card-bottom d-flex align-items-center justify-content-around">
                <div className="card-date-div d-flex flex-column align-items-center">
                  <span className="card-date-txt">
                    {item.item.transfer_date}
                  </span>
                  <span className="card-hour-txt">
                    {item.item.transfer_hour}
                  </span>
                </div>
                <div className="pt-3 pe-3">{greyArrow}</div>
                <div className="card-status-div d-flex flex-column align-items-center pb-3">
                  <span
                    className="card-status-txt"
                    style={{
                      color: item.item.driver_status_color_code,
                      fontSize: "small",
                    }}
                  >
                    {item.item.driver_status_title}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex container-fluid flex-column">
      <div className="home-black-header"></div>
      <Toaster />
      <div className="pool-profile-screen">
        <div
          className="text-center"
          onClick={() => (pool ? navigate("/Profil") : navigate("/Profilim"))}
        >
          <div className="ps-3" style={{ position: "absolute" }}>
            {leftArrow}
          </div>
          <span className="title-header-txt">Kazançlarım</span>
        </div>
        <div className="col-12 d-flex justify-content-between align-items-center mt-2">
          <div className="d-flex flex-column align-items-center col-6">
            <img className="profile-img" src={driverInfo.avatar}></img>
            <div className="mb-1">
              <StarRatings
                rating={
                  Number(driverInfo.driver_point) < 5
                    ? Number(driverInfo.driver_point)
                    : 5
                }
                starRatedColor="#ffd700"
                numberOfStars={5}
                starDimension="20"
                starSpacing="0"
              />
            </div>
          </div>
          <div className="col-6 d-flex flex-column">
            <span className="fw-bold fs-5">
              {driverInfo.name} {driverInfo.surname}
            </span>
            <span className="text-secondary">{driverInfo.plate}</span>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center pool-transfers-spinner pool-spinner-div">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <>
            <div className="d-flex flex-column mt-2 bg-white">
              <div className="d-flex justify-content-around py-3">
                <div className="col-3 d-flex flex-column align-items-center workcount rounded">
                  <span className="fw-semibold" style={{ fontSize: "small" }}>
                    İş Sayısı
                  </span>
                  <span style={{ fontSize: "small" }} className="mt-1">
                    {earnings.transfer_count}
                  </span>
                </div>
                <div className="col-3 d-flex flex-column align-items-center kmcount rounded">
                  <span className="fw-semibold" style={{ fontSize: "small" }}>
                    Toplam KM
                  </span>
                  <span style={{ fontSize: "small" }} className="mt-1">
                    {earnings.total_km} Km
                  </span>
                </div>
                <div className="col-3 d-flex flex-column align-items-center pointcount rounded">
                  <span className="fw-semibold" style={{ fontSize: "small" }}>
                    Puan
                  </span>
                  <span style={{ fontSize: "small" }} className="mt-1">
                    {earnings.point}
                  </span>
                </div>
              </div>
            </div>
            <div className="earning-list">
              <FlatList
                list={thisMonthTransfers}
                renderItem={<RenderEarnings />}
                renderWhenEmpty={() => (
                  <div className="d-flex justify-content-center pt-5">
                    <span>Bu aya ait bir kazanç bulunamadı.</span>
                  </div>
                )}
              />
            </div>
          </>
        )}
      </div>
      {pool ? (
        <div>
          <PoolBottomBar
            selectedHome={selectedHome}
            selectedPool={selectedPool}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goPoolHome={goPoolHome}
            goPoolPool={goPoolPool}
            goPoolNotification={goPoolNotification}
            goPoolProfile={goPoolProfile}
          />
        </div>
      ) : (
        <div>
          <AgencyBottomBar
            selectedHome={selectedHome}
            selectedTransfers={selectedTransfers}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goAgencyHome={goAgencyHome}
            goAgencyTransfers={goAgencyTransfers}
            goAgencyNotification={goAgencyNotification}
            goAgencyProfile={goAgencyProfile}
          />
        </div>
      )}
    </div>
  );
}

export default EarningScreen;
