import { PoolBottomBar } from "components";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { setDriverInfo, setHaveCar, setToken } from "@redux";
import { driverOffline } from "services";
import { Toaster } from "react-hot-toast";

function ProfileDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const group = useSelector((state) => state.persisted.group);
  const pool = useSelector((state) => state.persisted.pool);
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedPool, setSelectedPool] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(true);

  function goPoolHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolPool() {
    navigate("/Havuz");
    setSelectedHome(false);
    setSelectedPool(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolProfile() {
    navigate("/Profil");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function goPoolNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }

  const rightArrow = (
    <FontAwesomeIcon icon={faAngleRight} color="black" size={"2x"} />
  );
  const leftArrow = (
    <FontAwesomeIcon icon={faAngleLeft} color="black" size={"xl"} />
  );

  const styles = {
    avatar: {
      width: "24vw",
      height: "24vw",
      borderRadius: "12vw",
      borderWidth: "0.5vw",
      borderStyle: "solid",
      borderColor: "lightgrey",
    },
    titleTxt: {
      fontSize: "3.5vw",
      color: "black",
      fontWeight: "500",
    },
    txt: {
      fontSize: "3.5vw",
      color: "grey",
    },
  };

  return (
    <div className="d-flex container-fluid flex-column">
      <div className="home-black-header"></div>
      <Toaster />
      <div className="pool-profile-screen">
        <div
          className="text-center"
          onClick={() => (pool ? navigate("/Profil") : navigate("/Profilim"))}
        >
          <div className="ps-3" style={{ position: "absolute" }}>
            {leftArrow}
          </div>
          <span className="title-header-txt">Profil Bilgileri</span>
        </div>
        <div className="d-flex flex-column align-items-center mt-4">
          <img style={styles.avatar} src={driverInfo.avatar} />
          <span style={styles.txt} className="mt-1">
            <b className="text-dark">Sürücü ID: </b>
            {driverInfo.id}
          </span>
        </div>
        <div className="d-flex flex-column p-3">
          <div className="d-flex align-items-center border-bottom border-dark p-2 col-12">
            <span style={styles.titleTxt} className="col-4">
              Ad
            </span>
            <span style={styles.txt} className="col-8">
              : {driverInfo.name}
            </span>
          </div>
          <div className="d-flex align-items-center border-bottom border-dark p-2 col-12">
            <span style={styles.titleTxt} className="col-4">
              Soyad
            </span>
            <span style={styles.txt} className="col-8">
              : {driverInfo.surname}
            </span>
          </div>
          <div className="d-flex align-items-center border-bottom border-dark p-2 col-12">
            <span style={styles.titleTxt} className="col-4">
              Telefon
            </span>
            <span style={styles.txt} className="col-8">
              : {driverInfo.phone}
            </span>
          </div>
          <div className="d-flex align-items-center border-bottom border-dark p-2 col-12">
            <span style={styles.titleTxt} className="col-4">
              E-posta
            </span>
            <span style={styles.txt} className="col-8">
              : {driverInfo.email}
            </span>
          </div>
          <div className="d-flex align-items-center border-bottom border-dark p-2 col-12">
            <span style={styles.titleTxt} className="col-4">
              Plaka
            </span>
            <span style={styles.txt} className="col-8">
              : {driverInfo.plate}
            </span>
          </div>
        </div>
      </div>
      <div>
        <PoolBottomBar
          selectedHome={selectedHome}
          selectedPool={selectedPool}
          selectedProfile={selectedProfile}
          selectedNotification={selectedNotification}
          goPoolHome={goPoolHome}
          goPoolPool={goPoolPool}
          goPoolNotification={goPoolNotification}
          goPoolProfile={goPoolProfile}
        />
      </div>
    </div>
  );
}

export default ProfileDetail;
