import axios from "axios";
import { API_URL } from "constants";

export async function authRequest(URL, data) {
  const authAxios = axios.create();
  authAxios.defaults.timeout = 15000; // 15 sn
  authAxios.defaults.baseURL = API_URL;
  authAxios.defaults.headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  authAxios.interceptors.response.use(
    function (response) {
      console.log("authRequest successed");
      return response;
    },
    function (error) {
      if (error.response && error.response.status === 401) {
        console.log("authRequest failed");
      }
      return Promise.reject(error);
    }
  );
  return authAxios.post(`/${URL}`, data).then((response) => response.data);
}
