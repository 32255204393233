export const SET_AGENCY_TRANSFERS = "SET_AGENCY_TRANSFERS";
export const SET_POOL_TRANSFERS = "SET_POOL_TRANSFERS";
export const SET_TODAY_TRANSFERS = "SET_TODAY_TRANSFERS";
export const SET_TOMORROW_TRANSFERS = "SET_TOMORROW_TRANSFERS";
export const SET_FUTURE_TRANSFERS = "SET_FUTURE_TRANSFERS";
export const SET_PAST_TRANSFERS = "SET_PAST_TRANSFERS";
export const SET_POOL_TODAY_TRANSFERS = "SET_POOL_TODAY_TRANSFERS";
export const SET_POOL_TOMORROW_TRANSFERS = "SET_POOL_TOMORROW_TRANSFERS";
export const SET_POOL_FUTURE_TRANSFERS = "SET_POOL_FUTURE_TRANSFERS";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
