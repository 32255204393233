export const SET_TOKEN = "SET_TOKEN";
export const SET_POOL = "SET_POOL";
export const SET_GROUP = "SET_GROUP";
export const SET_TYPE = "SET_TYPE";
export const SET_HAVE_CAR = "SET_HAVE_CAR";
export const SET_DRIVERINFO = "SET_DRIVERINFO";
export const SET_TRANSFERDETAIL = "SET_TRANSFERDETAIL";
export const SET_TRANSFER_ID = "SET_TRANSFER_ID";
export const TRANSFER_DETAIL_TIME = "TRANSFER_DETAIL_TIME";
export const PICK_UP_TIME = "PICK_UP_TIME";
export const CALL_PASSENGER_TIME = "CALL_PASSENGER_TIME";

export const AUTH_INFO = "AUTH_INFO";
export const FCM_TOKEN = "FCM_TOKEN";
export const DRIVER_LOCATION = "DRIVER_LOCATION";
