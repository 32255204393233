import store from "@redux/store";
import toast from "react-hot-toast";

export const Notify = (
  { goHome, goPool, goExit, goTest },
  notificationScreen
) => {
  if (store.getState().persisted.token) {
    // if (notificationScreen !== "exit") {
    //   setTimeout(() => {
    //     toast.remove();
    //   }, 3000);
    // }
    toast.custom(
      <div
        style={styles.notificationBox}
        className="d-flex flex-column bg-white shadow justify-content-center border border-light rounded p-2"
      >
        <span className="d-flex justify-content-between align-items-center">
          <b>{store.getState().app.notification.title}</b>
          <button
            onClick={() => toast.remove()}
            type="button"
            className="btn-close"
            aria-label="Close"
            style={styles.closeBtn}
          ></button>
        </span>
        <span>{store.getState().app.notification.body}</span>
        <button
          onClick={() => {
            switch (notificationScreen) {
              case "home":
                goHome();
                break;
              case "pool":
                goPool();
                break;
              case "exit":
                goExit();
                break;
              default:
                break;
            }
            toast.remove();
          }}
          type="button"
          className="btn btn-secondary mt-2"
        >
          Tamam
        </button>
      </div>
    );
  } else {
    console.log("token yok");
  }
};

const styles = {
  notificationBox: {
    width: "80vw",
  },
  closeBtn: {
    fontSize: "4vw",
    zIndex: 200,
  },
};
