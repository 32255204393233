import store from "@redux/store";
import {
  setAgencyTransfers,
  setDriverInfo,
  setFutureTransfers,
  setPastTransfers,
  setPoolFutureTransfers,
  setPoolTodayTransfers,
  setPoolTomorrowTransfers,
  setPoolTransfers,
  setTodayTransfers,
  setTomorrowTransfers,
  setTransferDetail,
} from "@redux";
import { getRequest, postRequest, postRequestSaw } from "services";
import { END_POINTS } from "constants";
import qs from "qs";
import moment from "moment";
import { changeStatusAtFirebase } from "./changeStatusAtFirebase";

export async function getDriverInfo() {
  try {
    const driverInformations = await getRequest(END_POINTS.DRIVER_INFO);
    store.dispatch(setDriverInfo(driverInformations));
  } catch (error) {
    console.log("getDriverInfo - api function error: ", error);
  }
}

export async function driverOnline() {
  var config = qs.stringify({
    status: 1,
  });
  try {
    const driverStatus = await postRequest(
      END_POINTS.CHANGE_DRIVER_STATUS,
      config
    );
    changeStatusAtFirebase();
  } catch (error) {
    console.log("driverOnline - api function error: ", error);
  }
}

export async function driverOffline() {
  var config = qs.stringify({
    status: 0,
  });
  try {
    const driverStatus = await postRequest(
      END_POINTS.CHANGE_DRIVER_STATUS,
      config
    );
    changeStatusAtFirebase();
  } catch (error) {
    console.log("driverOffline - api function error: ", error);
  }
}

export async function getAgencyTransfers() {
  const startDate = moment().subtract(1, "day").format("YYYY-MM-DD");
  const endDate = moment().add(1, "months").format("YYYY-MM-DD");
  try {
    const allAgenyTransfers = await getRequest(
      `transfers?start_date=${startDate}&end_date=${endDate}`
    );
    await store.dispatch(setAgencyTransfers(allAgenyTransfers));
    const agencyTransfers = store.getState().app.agencyTransfers;
    //today home
    const todayHomeTransfers = agencyTransfers?.filter((f) => {
      const first = moment();
      const second = moment(f.transfer_date_time);
      const hoursDiff = first.diff(second, "hours");

      return (
        (f.transfer_date == moment().format("YYYY-MM-DD") && hoursDiff < 7) ||
        (f.transfer_date == moment().subtract(1, "day").format("YYYY-MM-DD") &&
          hoursDiff < 7)
      );
    });
    store.dispatch(setTodayTransfers(todayHomeTransfers));
    //tomorrow home
    const tomorrowHomeTransfers = agencyTransfers?.filter((f) => {
      return f.transfer_date == moment().add(1, "days").format("YYYY-MM-DD");
    });
    store.dispatch(setTomorrowTransfers(tomorrowHomeTransfers));
    //future home
    const futureHomeTransfers = agencyTransfers?.filter((f) => {
      return (
        moment(f.transfer_date).isAfter(moment().format("YYYY-MM-DD")) == true
      );
    });
    store.dispatch(setFutureTransfers(futureHomeTransfers));
    //past home
    // const pastHomeTransfers = agencyTransfers?.filter((f) => {
    //   return (
    //     moment(f.transfer_date).isBefore(moment().format("YYYY-MM-DD")) == true
    //   );
    // });
    // store.dispatch(setPastTransfers(pastHomeTransfers));
  } catch (error) {
    console.log("getAgencyTransfers - api function error: ", error);
  }
}

export async function getPoolTransfers() {
  const startDate = moment().format("YYYY-MM-DD");
  const endDate = moment().add(1, "months").format("YYYY-MM-DD");
  try {
    const allPoolTransfers = await getRequest(
      `pooltransfers?start_date=${startDate}&end_date=${endDate}`
    );
    store.dispatch(setPoolTransfers(allPoolTransfers));
    const poolTransfers = store.getState().app.poolTransfers;
    //today pool
    const todayPoolTransfers = poolTransfers.filter((f) => {
      return f.transfer_date == moment().format("YYYY-MM-DD");
    });
    store.dispatch(setPoolTodayTransfers(todayPoolTransfers));
    //tomorrow pool
    const tomorrowPoolTransfers = poolTransfers.filter((f) => {
      return f.transfer_date == moment().add(1, "days").format("YYYY-MM-DD");
    });
    store.dispatch(setPoolTomorrowTransfers(tomorrowPoolTransfers));
    //future pool
    const futurePoolTransfers = poolTransfers.filter((f) => {
      return (
        moment(f.transfer_date).isAfter(moment().format("YYYY-MM-DD")) == true
      );
    });
    store.dispatch(setPoolFutureTransfers(futurePoolTransfers));
  } catch (error) {
    console.log("getPoolTransfers - api function error: ", error);
  }
}

export async function getTransferDetail(id) {
  try {
    const transferDetail = await getRequest(
      `${END_POINTS.TRANSFER_DETAIL}/${id}`
    );
    store.dispatch(setTransferDetail(transferDetail));
  } catch (error) {
    console.log("getTransferDetail - api function error: ", error);
  }
}

export async function createFcmToken() {
  const fcmToken = store.getState().persisted.fcmToken;

  var data = qs.stringify({
    fcm_token: fcmToken,
  });

  try {
    const createdToken = await postRequest(END_POINTS.CREATE_FCM_TOKEN, data);
    console.log("createFcmToken, createdToken => ", createdToken);
  } catch (error) {
    console.log("createFcmToken - api function error: ", error);
  }
}

export async function callDrivers(number, queueName) {
  var config = qs.stringify({
    carnumber: number,
    queue_name: queueName,
  });
  console.log("callDrivers gelen number: ", number);
  console.log("callDrivers gelen qName: ", queueName);
  try {
    const callDriverRequest = await postRequestSaw(END_POINTS.CALL_CAR, config);
    console.log("callDriverRequest status: ", callDriverRequest.status);
    if (callDriverRequest.status !== 1) {
      console.log("Çağırma işleminde hata oluştu. Lütfen tekrar deneyin.");
    } else {
      console.log("callDrivers status 1 ========== ");
      // setTimeout(() => {
      //   setCallSpinner(false);
      // }, 100);
    }
  } catch (error) {
    // setCallSpinner(false);
    console.log("callDrivers api function error: ", error);
  }
}
