const API_URL = "https://api.govip.com.tr/api/";
const SAW_URL = "https://api.sawkoop.com/api/";
// GOVIP
const VAPID_KEY =
  "BCr_bxUiFWRmVOj2DiuKPzGf7c2bxlZ_NHEmhMcKRTTDxhtZzhHbuGjbmKULfQC0BWIRyYuIJrOoMTjRrqQNdU4";

// SAWKOOP
// const VAPID_KEY =
//   "BPJJwLYleTLapqqfKGLEee-4lD8iuZ6BgC4H84inkrynN2GjjzKuRvDR-iuvWDJQp5GHdzb1UHzKyKFryKDD8Fg";

const END_POINTS = {
  LOGIN: "auth/login/driverlogin",
  DRIVER_INFO: "drivers/driver",
  TODAY_TRANSFERS: "transfers/today",
  TOMORROW_TRANSFERS: "transfers/tomorrow",
  FUTURE_TRANSFERS: "transfers/future",
  PAST_TRANSFERS: "transfers/past",
  TRANSFER_DETAIL: "transfers/detail",
  CHANGE_TRANSFER_STATUS: "transfers/changestatus",
  CARS: "drivers/cars",
  SELECT_CAR: "drivers/selectcar",
  CHANGE_DRIVER_STATUS: "drivers/changedriverstatus",
  TODAY_POOL_TRANSFERS: "transfers/pool/today",
  TOMORROW_POOL_TRANSFERS: "transfers/pool/tomorrow",
  FUTURE_POOL_TRANSFERS: "transfers/pool/future",
  CHANGE_BANK_ACCOUNT_INFO: "drivers/changebankinfo",
  EARNINGS: "drivers/earnings",
  NOTIFICATIONS: "drivers/notifications",
  DEFENSES: "drivers/defenses",
  DEFENSES_DETAIL: "drivers/defense/detail",
  ADD_DEFENSE_ANSWER: "drivers/adddefense",
  SETTINGS: "settings",
  CREATE_FCM_TOKEN: "auth/login/createtoken",
  NEW_DRIVER: "drivers/newdriver",
  CALL_CAR: "callCars",
  EXIT_QUEUE: "exitFromQueue",
};

//https://api.govip.com.tr/api/drivers/testfunction

export { API_URL, SAW_URL, END_POINTS, VAPID_KEY };
