import axios from "axios";
import { API_URL } from "constants";
import { setToken } from "@redux";
import store from "@redux/store";

export async function postRequest(URL, data) {
  const reduxData = store.getState();
  const token = reduxData.persisted.token;
  const driverInfo = reduxData.persisted.driverInfo;
  const status = driverInfo.status;
  const postAxios = axios.create();

  postAxios.defaults.timeout = 15000; // 15 sn
  postAxios.defaults.baseURL = API_URL;
  postAxios.defaults.headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  postAxios.interceptors.response.use(
    function (response) {
      if (driverInfo.length !== 0) {
        if (status !== 1) {
          console.log("postRequest, sürücü ihraç edilmiş, token siliniyor.");
          store.dispatch(setToken(""));
        } else {
          console.log("postRequest successed");
          return response;
        }
      } else {
        console.log("postRequest, driverInfo boş döndü.");
      }
    },
    function (error) {
      if (error.response && error.response.status === 401) {
        console.log("postRequest failed");
      }
      return Promise.reject(error);
    }
  );
  return postAxios.post(`/${URL}`, data).then((response) => response.data);
}
