import { mapTransferBg, transferIcon } from "assets";
import React from "react";

function CustomTransferIcon({ position }) {
  return (
    <div className="custom-marker-img" style={{ zIndex: 99 }}>
      <img className="custom-marker-img" src={mapTransferBg}></img>
    </div>
  );
}

export default CustomTransferIcon;
