import React, { useEffect, useState } from "react";
import "./PoolHomeScreen.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { carIcon, greenPriceBg, orangePriceBg } from "assets";
import qs from "qs";
import StarRatings from "react-star-ratings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCaretDown,
  faRoad,
  faUsers,
  faSuitcase,
  faLocationDot,
  faClock,
  faCar,
  faSquarePhoneFlip,
} from "@fortawesome/free-solid-svg-icons";
import { PoolBottomBar } from "components";
import FlatList from "flatlist-react";
import Switch from "react-switch";
import {
  driverOnline,
  getAgencyTransfers,
  getTransferDetail,
  driverOffline,
  postRequest,
  getRequest,
  createFcmToken,
} from "services";
import { END_POINTS } from "constants";
import moment from "moment";
import { ref, update } from "firebase/database";
import { db } from "services/firebaseConfig";
import { setDriverInfo, setDriverLocation, setHaveCar } from "@redux";
import { Button, Row, Col, Toast } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import PullToRefresh from "react-simple-pull-to-refresh";

function PoolHomeScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const group = useSelector((state) => state.persisted.group);
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const transferDetail = useSelector((state) => state.persisted.transferDetail);
  const todayTransfers = useSelector((state) => state.app.todayTransfers);
  const tomorrowTransfers = useSelector((state) => state.app.tomorrowTransfers);
  const futureTransfers = useSelector((state) => state.app.futureTransfers);
  const [selectedHome, setSelectedHome] = useState(true);
  const [selectedPool, setSelectedPool] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmSpinner, setConfirmSpinner] = useState(true);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedTransferId, setSelectedTransferId] = useState("");
  const [modalSpinner, setModalSpinner] = useState(true);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [day, setDay] = useState("today");
  const [show, setShow] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const greenArrow = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faCaretRight}
      color="green"
      size={"2x"}
    />
  );

  const greyArrow = (
    <FontAwesomeIcon
      className="card-grey-arrow"
      icon={faCaretDown}
      color="grey"
      size={"2x"}
    />
  );

  const suitcaseIcon = (
    <FontAwesomeIcon
      className="modal-suitcase"
      icon={faSuitcase}
      color="grey"
      size={"2x"}
    />
  );

  const usersIcon = (
    <FontAwesomeIcon
      className="modal-users"
      icon={faUsers}
      color="grey"
      size={"2x"}
    />
  );

  const roadIcon = (
    <FontAwesomeIcon
      className="modal-road"
      icon={faRoad}
      color="grey"
      size={"2x"}
    />
  );

  const phoneIcon = (
    <FontAwesomeIcon icon={faSquarePhoneFlip} color="black" size={"1x"} />
  );

  const pickUpIcon = (
    <FontAwesomeIcon icon={faLocationDot} color="green" size={"xs"} />
  );

  const dropOffIcon = (
    <FontAwesomeIcon icon={faLocationDot} color="red" size={"xs"} />
  );

  const detailUsersIcon = (
    <FontAwesomeIcon icon={faUsers} color="black" size={"xs"} />
  );
  const detailSuitcaseIcon = (
    <FontAwesomeIcon icon={faSuitcase} color="black" size={"xs"} />
  );
  const detailRoadIcon = (
    <FontAwesomeIcon icon={faRoad} color="black" size={"xs"} />
  );
  const detailClockIcon = (
    <FontAwesomeIcon icon={faClock} color="black" size={"xs"} />
  );
  const detailCarIcon = (
    <FontAwesomeIcon icon={faCar} color="black" size={"xs"} />
  );

  async function checkSelectedCar() {
    try {
      const driverInformations = await getRequest(END_POINTS.DRIVER_INFO);
      dispatch(setDriverInfo(driverInformations));
      if (driverInformations.car_id == null) {
        dispatch(setHaveCar(false));
        navigate("/");
      } else {
        dispatch(setHaveCar(true));
      }
    } catch (error) {
      console.log("getDriverInfo - api function error: ", error);
    }
  }

  function goPoolHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolPool() {
    navigate("/Havuz");
    setSelectedHome(false);
    setSelectedPool(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolProfile() {
    navigate("/Profil");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function goPoolNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }

  function getWatchPosition() {
    console.log("watch run");
    navigator.geolocation.watchPosition((position) => {
      console.log(position);
      console.log(
        "watch position: ",
        "latitude: ",
        position.coords.latitude,
        "longitude: ",
        position.coords.longitude
      );
      if (position.coords.latitude !== 0 && position.coords.longitude !== 0) {
        dispatch(
          setDriverLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      }
      update(ref(db, "pudriver/drivers/" + driverInfo.id), {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        bearing: position.coords.heading,
        speed: position.coords.speed,
        time: position.timestamp,
        accuracy: position.coords.accuracy,
        plate: driverInfo.plate,
        update_time: moment().locale("tr").format("YYYY-MM-DD HH:mm:ss"),
      });
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }
  async function onConfirm() {
    if (transferDetail.driver_status_id == 1) {
      var data = qs.stringify({
        id: transferDetail.id,
        status: 1,
        latitude: location.latitude,
        longitude: location.longitude,
      });
      try {
        const newTransferStatus = await postRequest(
          END_POINTS.CHANGE_TRANSFER_STATUS,
          data
        );
        if (newTransferStatus.status_code == 1) {
          console.log("PoolHomeScreen - onConfirm function - başarılı");
          getTransferDetail(transferDetail.id);
          getAgencyTransfers();
          setOpenDetailModal(false);
        } else {
          console.log("PoolHomeScreen - onConfirm function - başarısız");
        }
      } catch (error) {
        console.log("PoolHomeScreen - onConfirm function - error: ", error);
      }
    } else if (transferDetail.driver_status_id > 1) {
      navigate("/Transfer-Detayi");
    } else {
      console.log(
        "PoolHomeScreen - onConfirm function - transfer adımı hatası"
      );
    }
  }

  useEffect(() => {
    driverOnline();
    getAgencyTransfers();
    createFcmToken();
    getWatchPosition();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const TransferConfirmModal = () => {
    return (
      <div className="confirm-modal-container d-flex justify-content-center">
        <div className="confirm-modal-sub-container shadow-lg">
          {confirmSpinner ? (
            <div className="confirm-modal-transfer-detail mt-2">
              <p className="placeholder-glow d-flex flex-column align-items-center pt-3">
                <span className="placeholder col-10"></span>
                <span className="placeholder mt-1 col-11"></span>
                <span className="placeholder mt-1 col-11"></span>
                <span className="placeholder mt-1 col-11"></span>
              </p>
            </div>
          ) : (
            <div className="confirm-modal-transfer-detail mt-2">
              <div
                className="d-flex align-items-center justify-content-between p-1 px-2"
                style={{ width: "75vw" }}
              >
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    {pickUpIcon}
                    <span className="ms-1" style={{ fontSize: "x-small" }}>
                      <b>Alış Yeri:</b> {transferDetail.short_start_address}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    {dropOffIcon}
                    <span className="ms-1" style={{ fontSize: "x-small" }}>
                      <b>Bırakış Yeri:</b> {transferDetail.short_end_address}
                    </span>
                  </div>
                </div>
                <div className="">
                  <span className="" style={{ fontSize: "x-small" }}>
                    <b>
                      {transferDetail.price} {transferDetail.currency}
                    </b>
                  </span>
                </div>
              </div>
              <div className="confirm-separator"></div>
              <div
                className="d-flex align-items-center justify-content-between p-1 px-2"
                style={{ width: "75vw" }}
              >
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    {detailUsersIcon}
                    <span className="ms-1" style={{ fontSize: "x-small" }}>
                      {transferDetail.number_of_person} Kişi
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    {detailSuitcaseIcon}
                    <span className="ms-1" style={{ fontSize: "x-small" }}>
                      {transferDetail.number_of_suitcases} Valiz
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center ">
                    {detailRoadIcon}
                    <span className="ms-1" style={{ fontSize: "x-small" }}>
                      {transferDetail.distance} KM
                    </span>
                  </div>
                  <div className="d-flex align-items-center ">
                    {detailClockIcon}
                    <span className="ms-1" style={{ fontSize: "x-small" }}>
                      {transferDetail.transfer_hour}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    {detailCarIcon}
                    <span className="ms-1" style={{ fontSize: "x-small" }}>
                      {transferDetail.car_type}
                    </span>
                  </div>
                </div>
              </div>
              <div className="confirm-separator"></div>
              <div className="px-2" style={{ width: "75vw" }}>
                <span style={{ fontSize: "x-small" }}>
                  <b className="me-1">Not:</b>
                  {transferDetail.transfer_note}
                </span>
              </div>
              <div className="confirm-separator"></div>
            </div>
          )}
          <div className="confirm-txt-container mt-1">
            <span className="text-danger fs-6 fw-bold">ÖNEMLİ</span>
            <div className="confirm-first-txt">
              Transfer kabul edildikten sonra iptal edilemez. Edilmesi durumunda
              bilet tutarının <b className="text-danger">%50 kesinti</b>{" "}
              uygulanır.
            </div>
            <div className="mt-2 confirm-second-txt">
              Transfer saatine 3 saat kala iptal edilen işlerde %100 kesinti
              uygulanır.
            </div>
            <div className="d-flex flex-column mt-2 confirm-third-txt">
              <span className="fw-bold text-secondary">Yolcu alış yerine</span>
              <span>15 dk dan fazla geç kalınması: %30</span>
              <span>30 dk dan fazla geç kalınması: %50</span>
              <span>
                Yolcu alış noktasına gidilmemesi veya yolcunun aracı bulumaması
                durumunda %100 kesinti uygulanır.
              </span>
            </div>
            <div className="mt-2 confirm-forth-txt">
              <span className="d-flex justify-content-center confirm-fifth-txt">
                Transferi onaylıyor musunuz?
              </span>
              <div className="d-flex justify-content-around align-items-center">
                <span
                  onClick={() => setOpenConfirmModal(false)}
                  className="text-success fw-bold fs-6"
                >
                  İPTAL
                </span>
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    onClick={onConfirm}
                    className="text-success fw-bold fs-6"
                  >
                    ONAYLA
                  </span>
                  {spinner && (
                    <div className="clearfix">
                      <div
                        className="spinner-border float-end ms-1 text-success"
                        style={{ width: "4vw", height: "4vw" }}
                        role="status"
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TransferDetailModal = () => {
    return modalSpinner ? (
      <div className="d-flex justify-content-center align-items-center modal-spinner container-fluid">
        <div className="spinner-border" role="status"></div>
      </div>
    ) : (
      <div
        className="detail-modal-container d-flex container-fluid"
        style={
          (day == "today" &&
            todayTransfers[todayTransfers.length - 1].id ==
              transferDetail.id) ||
          (day == "tomorrow" &&
            tomorrowTransfers[tomorrowTransfers.length - 1].id ==
              transferDetail.id) ||
          (day == "future" &&
            futureTransfers[futureTransfers.length - 1].id == transferDetail.id)
            ? { marginBottom: "12vh" }
            : { marginBottom: 0 }
        }
      >
        <div className="detail-modal-sub-container d-flex flex-column">
          <div className="detail-modal-icon-container d-flex justify-content-around mt-2">
            <div className="d-flex align-items-center">
              {usersIcon}
              <span className="ms-1 fs-5 fw-semibold">
                {transferDetail.number_of_person}
              </span>
            </div>
            <div className="d-flex align-items-center">
              {suitcaseIcon}
              <span className="ms-1 fs-5 fw-semibold">
                {transferDetail.number_of_suitcases}
              </span>
            </div>
            <div className="d-flex align-items-center">
              {roadIcon}
              <span className="ms-1 fs-6 fw-semibold">
                {transferDetail.distance}KM
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center modal-note mt-2 ps-2">
            <span className="fs-5 text-secondary">Not:</span>
            <span className="ms-1 fs-6">{transferDetail.transfer_note}</span>
          </div>
          <div className="d-flex align-self-center mt-2 mb-2">
            <button
              type="button"
              className="btn btn-primary modal-btn fw-bold fs-5"
              onClick={() => {
                if (transferDetail.driver_status_id == 1) {
                  setOpenConfirmModal(true);
                  setTimeout(() => {
                    setConfirmSpinner(false);
                  }, 1000);
                } else {
                  navigate("/Transfer-Detayi");
                }
              }}
            >
              {transferDetail.driver_status_id == 1 ? "ONAYLA" : "GİT"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderHomeTransfers = (item) => {
    return (
      <>
        <div
          className="pt-2 pb-1 d-flex justify-content-center"
          onClick={() => {
            if (item.item.driver_status_id !== 6) {
              getTransferDetail(item.item.id);
            }
            if (
              item.item.driver_status_id == 1 ||
              item.item.driver_status_id == 2
            ) {
              getTransferDetail(item.item.id);
              if (openDetailModal && selectedTransferId == item.item.id) {
                setOpenDetailModal(false);
                setSelectedTransferId("");
              } else {
                setModalSpinner(true);
                getTransferDetail(item.item.id);
                setOpenDetailModal(true);
                setSelectedTransferId(item.item.id);
                setTimeout(() => {
                  setModalSpinner(false);
                }, 1000);
              }
            } else {
              if (transferDetail.driver_status_id == 3) {
                navigate("/Transfer-Detayi");
              } else if (transferDetail.driver_status_id == 4) {
                navigate("/Yolcu-Alis-Noktasi");
              } else if (transferDetail.driver_status_id == 5) {
                navigate("/Yolcu-Alindi-Veya-Bulunamadi");
              } else if (transferDetail.driver_status_id == 6) {
                console.log("status == 6, disable click");
              }
            }
          }}
        >
          <div className="home-render-container shadow-sm d-flex justify-content-center">
            <div
              className="home-render-status-bar"
              style={{ backgroundColor: item.item.driver_status_color_code }}
            ></div>
            <div className="home-render-transfer-card d-flex flex-column">
              <div className="home-render-card-top d-flex align-items-center justify-content-around">
                <div className="card-short-div">
                  <span className="card-short-txt">
                    {item.item.short_start_address}
                  </span>
                </div>
                <div>{greenArrow}</div>
                <div className="card-short-div">
                  <span className="card-short-txt">
                    {item.item.short_end_address}
                  </span>
                </div>
              </div>
              <div className="home-card-separator"></div>
              <div className="home-render-card-bottom d-flex align-items-center ">
                <div className="card-date-div d-flex flex-column align-items-center">
                  <span className="card-date-txt">
                    {item.item.transfer_date}
                  </span>
                  <span className="card-hour-txt">
                    {item.item.transfer_hour}
                  </span>
                </div>
                <div className="card-status-div d-flex flex-column align-items-center">
                  <span
                    className="card-status-txt"
                    style={{
                      color: item.item.driver_status_color_code,
                      fontSize: "small",
                    }}
                  >
                    {item.item.driver_status_title}
                  </span>
                  <div>{greyArrow}</div>
                </div>
                <div className="card-price-div d-flex flex-column align-items-center">
                  <img
                    className="card-price-img"
                    src={
                      item.item.driver_status_id == 2 ||
                      item.item.driver_status_id == 6
                        ? greenPriceBg
                        : orangePriceBg
                    }
                  />
                  <span className="card-price-txt">
                    {item.item.price} {item.item.currency}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openDetailModal && item.item.id == selectedTransferId ? (
          <TransferDetailModal />
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div className="home-screen container-fluid">
      <div className="pool-home-header">
        <Toaster />
        <div className="home-black-header"></div>
        <div className="home-container d-flex justify-content-center flex-column">
          <div className="d-flex justify-content-around align-items-center mt-2">
            <div className="home-avatar-container d-flex flex-column align-items-center">
              <div
                className="home-avatar-point"
                style={
                  driverInfo.online
                    ? { backgroundColor: "#47a716" }
                    : { backgroundColor: "grey" }
                }
              ></div>
              <img className="home-avatar rounded" src={driverInfo.avatar} />
              <div className="mb-1">
                <StarRatings
                  rating={
                    Number(driverInfo.driver_point) < 5
                      ? Number(driverInfo.driver_point)
                      : 5
                  }
                  starRatedColor="#ffd700"
                  numberOfStars={5}
                  starDimension="20"
                  starSpacing="0"
                />
              </div>
            </div>
            <div className="home-txt-container d-flex flex-column align-items-center">
              <div className="d-flex align-items-center">
                <Switch
                  onChange={() => {
                    if (driverInfo.online == 1) {
                      driverOffline();
                    } else {
                      driverOnline();
                    }
                  }}
                  checked={driverInfo.online == 1 ? true : false}
                  onColor="#47a716"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                <span className="switch-txt ms-1">
                  {driverInfo.online == 1 ? "Online" : "Offline"}
                </span>
              </div>
              <div>
                <span className="home-name-txt">
                  {driverInfo.name} {driverInfo.surname}
                </span>
              </div>
              <div>
                <span className="home-plate-txt">{driverInfo.plate}</span>
              </div>
              {group == 3 ? (
                <button
                  onClick={() => dispatch(setHaveCar(false))}
                  type="button"
                  className="btn btn-primary pick-up-btn change-car-btn rounded-5 mb-2"
                >
                  Araç Değiştir
                </button>
              ) : (
                <></>
              )}
            </div>
            {group == 3 && (
              <div
                // onClick={() => navigate("/Kuyruk")}
                className="d-flex flex-column align-items-center justify-content-center"
              >
                <div className="car-number-div">
                  <span className="car-number-txt">0</span>
                </div>
                <div className="mt-1">
                  <img src={carIcon}></img>
                </div>
              </div>
            )}
          </div>
        </div>
        {openConfirmModal ? (
          <div onClick={() => setOpenConfirmModal(false)}>
            <TransferConfirmModal />
          </div>
        ) : (
          <></>
        )}
        <div className="day-tab-container d-flex justify-content-around">
          <div
            className="day-tab-div d-flex d-flex justify-content-center align-items-center"
            onClick={() => setDay("today")}
          >
            <div
              className={
                day == "today"
                  ? "day-name-view d-flex justify-content-center align-items-center"
                  : "day-name-view-default d-flex justify-content-center align-items-center"
              }
            >
              <span
                className={
                  day == "today" ? "day-name-txt" : "day-name-txt-default"
                }
              >
                Bugün
              </span>
            </div>
            <div
              className={
                day == "today" ? "day-count-view" : "day-count-view-default"
              }
            >
              <span
                className={
                  day == "today" ? "day-count-txt" : "day-count-txt-default"
                }
              >
                {todayTransfers?.length}
              </span>
            </div>
          </div>

          <div
            className="day-tab-div d-flex d-flex justify-content-center align-items-center"
            onClick={() => setDay("tomorrow")}
          >
            <div
              className={
                day == "tomorrow"
                  ? "day-name-view  d-flex justify-content-center align-items-center"
                  : "day-name-view-default d-flex justify-content-center align-items-center"
              }
            >
              <span
                className={
                  day == "tomorrow" ? "day-name-txt" : "day-name-txt-default"
                }
              >
                Yarın
              </span>
            </div>
            <div
              className={
                day == "tomorrow" ? "day-count-view" : "day-count-view-default"
              }
            >
              <span
                className={
                  day == "tomorrow" ? "day-count-txt" : "day-count-txt-default"
                }
              >
                {tomorrowTransfers?.length}
              </span>
            </div>
          </div>

          <div
            className="day-tab-div d-flex d-flex justify-content-center align-items-center"
            onClick={() => setDay("future")}
          >
            <div
              className={
                day == "future"
                  ? "day-name-view d-flex justify-content-center align-items-center"
                  : "day-name-view-default d-flex justify-content-center align-items-center"
              }
            >
              <span
                className={
                  day == "future" ? "day-name-txt" : "day-name-txt-default"
                }
              >
                Gelecek
              </span>
            </div>
            <div
              className={
                day == "future" ? "day-count-view" : "day-count-view-default"
              }
            >
              <span
                className={
                  day == "future" ? "day-count-txt" : "day-count-txt-default"
                }
              >
                {futureTransfers?.length}
              </span>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center pool-transfers-spinner pool-spinner-div">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          <PullToRefresh
            onRefresh={() => getAgencyTransfers()}
            pullingContent={""}
          >
            <div className="home-transfer-list">
              <FlatList
                list={
                  day == "today"
                    ? todayTransfers
                    : day == "tomorrow"
                    ? tomorrowTransfers
                    : futureTransfers
                }
                renderItem={<RenderHomeTransfers />}
                renderWhenEmpty={() => (
                  <div className="d-flex justify-content-center pt-5">
                    <span>
                      {day == "today"
                        ? "Bugüne ait bir transfer bulunamadı."
                        : day == "tomorrow"
                        ? "Yarına ait bir transfer bulunamadı."
                        : "Gelecek tarihli bir transfer bulunamadı."}
                    </span>
                  </div>
                )}
              />
            </div>
          </PullToRefresh>
        </>
      )}
      <div>
        <PoolBottomBar
          selectedHome={selectedHome}
          selectedPool={selectedPool}
          selectedNotification={selectedNotification}
          selectedProfile={selectedProfile}
          goPoolHome={goPoolHome}
          goPoolPool={goPoolPool}
          goPoolNotification={goPoolNotification}
          goPoolProfile={goPoolProfile}
        />
      </div>
    </div>
  );
}

export default PoolHomeScreen;
