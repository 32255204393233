import { setDriverInfo } from "@redux";
import store from "@redux/store";
import { END_POINTS } from "constants";
import { ref, update } from "firebase/database";
import moment from "moment";
import { getRequest } from "services/Axios/getRequest";
import { db } from "services/firebaseConfig";

export async function changeStatusAtFirebase() {
  try {
    const driverInformations = await getRequest(END_POINTS.DRIVER_INFO);
    store.dispatch(setDriverInfo(driverInformations));

    update(ref(db, "pudriver/drivers/" + driverInformations.id), {
      online: driverInformations.online,
      car_icon: driverInformations.car_icon,
      update_time: moment().locale("tr").format("YYYY-MM-DD HH:mm:ss"),
    });
    console.log("changeStatusAtFirebase, run");
  } catch (error) {
    console.log("changeStatusAtFirebase, get driverInfo error: ", error);
  }
}
