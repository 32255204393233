import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlatList from "flatlist-react";
import "./ChooseQueue.css";
import { faArrowRight, faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-popup-alert/dist/index.css";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { db } from "services/firebaseConfig";
import { setToken } from "@redux";

function ChooseQueue() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [showEmptyAlert, setShowEmptyAlert] = useState(false);
  const [selected, setSelected] = useState(false);
  const [queues, setQueues] = useState([]); //[{"count": 3, "name": "Alt Kat"}, {"count": 1, "name": "Üst Kat"}]
  const [queueTitles, setQueueTitles] = useState([]); //["queue_alt-kat-4", "queue_ust-kat-5"]
  const [queueName, setQueueName] = useState(""); //queue_alt-kat-4
  const [queueTitle, setQueueTitle] = useState(""); //Alt Kat

  useEffect(() => {
    onValue(ref(db, "queue_counts"), (snapshot) => {
      const queueCounts = snapshot.val();
      if (queueCounts) {
        // queue title
        const queueCountsTitleArr = Object.keys(queueCounts);
        setQueueTitles(queueCountsTitleArr);
        // queue info
        const queueCountsArr = Object.values(queueCounts);
        setQueues(queueCountsArr);
      } else {
        console.log("queue counts empty");
      }
    });
  }, []);

  const gateArrow = (
    <FontAwesomeIcon icon={faArrowRight} color={"black"} size={"1x"} />
  );
  const BackIcon = (
    <FontAwesomeIcon icon={faCircleLeft} color="black" size={"xl"} />
  );

  const RenderCars = (item) => {
    return (
      <div
        onClick={() => {
          setSelected(true);
          setQueueName(queueTitles[queues.indexOf(item.item)]);
          setQueueTitle(item.item.name);
        }}
        className="d-flex align-items-center justify-content-between p-3 bg-white border border-1  shadow-sm col-10 offset-1 rounded mb-4 render-car-list"
      >
        <div className="">{gateArrow}</div>
        <div className="d-flex flex-column col-8">
          <span className="render-plate-txt">
            {/* {item?.item?.replace(`queue_${gateName}_`, "")} */}
            {/* {item?.item
              ?.replace("queue_", "")
              .split("-")
              .slice(0, -1)
              .join(" ")} */}
            {item?.item?.name}
          </span>
        </div>
        {selected && queueTitle == item.item.name ? (
          <div className="default-check border border-dark border-1 d-flex justify-content-center align-items-center">
            <div className="selected-check"></div>
          </div>
        ) : (
          <div className="default-check border border-dark border-1"></div>
        )}
      </div>
    );
  };

  return (
    <div className="choose-car-screen">
      <div className="home-black-header"></div>
      {showAlert && (
        <div className="select-car-alert">
          <div className="select-car-alert-container">
            <div>
              <span className="text-center d-flex fw-semibold ms-4 ps-3">
                Kuyruk seçimi boş bırakılamaz.
              </span>
            </div>
            <div className="d-flex justify-content-around mt-4">
              <button
                type="button"
                className="btn btn-outline-primary col-4"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                Tamam
              </button>
            </div>
          </div>
        </div>
      )}
      {showEmptyAlert && (
        <div className="select-car-alert">
          <div className="select-car-alert-container">
            <div>
              <span className="text-center d-flex fw-semibold">
                Seçebileceğiniz bir kuyruk yok. Kapı seçimine
                yönlendiriliyorsunuz.
              </span>
            </div>
            <div className="d-flex justify-content-around mt-3">
              <button
                type="button"
                className="btn btn-outline-danger col-4"
                onClick={() => {
                  setShowEmptyAlert(false);
                  navigate(-1);
                }}
              >
                Tamam
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        onClick={() => dispatch(setToken(""))}
        className="d-flex align-items-center mt-1 ms-2"
      >
        <div>{BackIcon}</div>
        <span className="ms-2" style={styles.backTxt}>
          Çıkış
        </span>
      </div>
      <div
        className="fw-semibold mt-3"
        style={{
          height: "7vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          fontSize: "4.5vw",
        }}
      >
        <span>Kuyruklardan birini seçiniz.</span>
      </div>
      <div className="queue-flat-list pt-3">
        <FlatList
          list={queues}
          renderItem={<RenderCars />}
          renderWhenEmpty={() => (
            <div className="d-flex justify-content-center pt-5">
              <span>Size atanmış bir kuyruk bulunmamaktadır.</span>
            </div>
          )}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary col-8 offset-2 mt-5 p-2"
        onClick={() => {
          if (!selected) {
            setShowAlert(true);
          } else {
            navigate({
              pathname: "/Arac-Cagirma",
              search: createSearchParams({
                queueTitle: queueTitle,
                queueName: queueName,
              }).toString(),
            });
          }
        }}
      >
        Kuyruk Seç
      </button>
    </div>
  );
}

export default ChooseQueue;

const styles = {
  backTxt: {
    fontSize: "5vw",
  },
  emptyTxt: {
    fontSize: "3.5vw",
  },
  carIconDiv: {},
  carIconImg: {
    width: "10vw",
    height: "10vw",
  },
  queueTxtDiv: {
    width: "7vw",
    height: "7vw",
    borderRadius: "3.5vw",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  myQueueTxtDiv: {
    width: "7vw",
    height: "7vw",
    borderRadius: "3.5vw",
    backgroundColor: "rgba(233, 233, 233)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  nameTxt: {
    fontSize: "4.5vw",
    fontWeight: "500",
    color: "black",
  },
  plateTxt: {
    fontSize: "3.5vw",
    fontWeight: "500",
    color: "grey",
  },
};
