import { duration } from "moment";
import React from "react";
import "./Timer.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

function Timer({ remainingTimer, setOpenTimer }) {
  const renderTime = (time) => {
    return (
      <div className="time-wrapper timer-txt d-flex flex-column align-items-center">
        <div>Transferi</div>
        <div>ilerletebilmeniz</div>
        <div>için kalan süre:</div>
        <div className="time fs-4 fw-bold">{time}</div>
        <div>saniye</div>
      </div>
    );
  };
  const getTimeSeconds = (time) => (remainingTimer - time) | 0;
  return (
    <div className="timer-container container-fluid d-flex justify-content-center align-items-center">
      <div className="bg-white rounded p-4 d-flex flex-column align-items-center">
        <CountdownCircleTimer
          isPlaying
          duration={remainingTimer}
          colors="#000000"
          onComplete={() => setOpenTimer(false)}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime(getTimeSeconds(elapsedTime))}
            </span>
          )}
        </CountdownCircleTimer>
        <button
          type="button"
          className="btn btn-outline-dark mt-2"
          onClick={() => setOpenTimer(false)}
        >
          Tamam
        </button>
      </div>
    </div>
  );
}

export default Timer;
