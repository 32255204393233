import { setFcmToken } from "@redux";
import store from "@redux/store";
import { VAPID_KEY } from "constants";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// GOVIP FB CONFIG
const firebaseConfig = {
  apiKey: "AIzaSyBKyKjbMjKZuL6bKpqLuybLfmZaTzXZuVA",
  authDomain: "pudriver-3c333.firebaseapp.com",
  databaseURL:
    "https://pudriver-3c333-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pudriver-3c333",
  storageBucket: "pudriver-3c333.appspot.com",
  messagingSenderId: "244827580037",
  appId: "1:244827580037:web:4fb5807143e659890eebec",
  measurementId: "G-04YM11JLZE",
};

// SAWKOOP FB CONFIG
// const firebaseConfig = {
//   apiKey: "AIzaSyDUHFPCrYPDCNDqv4J1v6g3XAIbL5QTWMo",
//   authDomain: "sawkoop-gate.firebaseapp.com",
//   databaseURL:
//     "https://sawkoop-gate-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "sawkoop-gate",
//   storageBucket: "sawkoop-gate.appspot.com",
//   messagingSenderId: "1033778268414",
//   appId: "1:1033778268414:web:1f4ef68660c92c3a6b7daf",
//   measurementId: "G-X8PTB9VB6C",
// };

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const db = getDatabase(app);

// export const getOrRegisterServiceWorker = async () => {
//   if ("serviceWorker" in navigator) {
//     return window.navigator.serviceWorker
//       .getRegistration("/firebase-push-notification-scope")
//       .then((serviceWorker) => {
//         if (serviceWorker) return serviceWorker;
//         return window.navigator.serviceWorker.register(
//           "/firebase-messaging-sw.js",
//           {
//             scope: "/firebase-push-notification-scope",
//           }
//         );
//       });
//   }
//   throw new Error("The browser doesn`t support service worker.");
// };

export const getTokenFound = async () => {
  //getOrRegisterServiceWorker();
  let currentToken = "";
  try {
    currentToken = await getToken(messaging, { vapidKey: VAPID_KEY });
    if (currentToken) {
      console.log("CURRENT TOKEN", currentToken);
      store.dispatch(setFcmToken(currentToken));
    } else {
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
