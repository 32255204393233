import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PoolBottomBar } from "components";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./BankAccountScreen.css";
import qs from "qs";
import { getRequest, postRequest } from "services";
import { END_POINTS } from "constants";
import { setDriverInfo } from "@redux";
import { Toaster } from "react-hot-toast";

function BankAccountScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const group = useSelector((state) => state.persisted.group);
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedPool, setSelectedPool] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [bankName, setBankName] = useState(driverInfo.bank);
  const [driverName, setDriverName] = useState(driverInfo.bank_name_surname);
  const [driverIban, setDriverIban] = useState(driverInfo.iban);

  const leftArrow = (
    <FontAwesomeIcon icon={faAngleLeft} color="black" size={"xl"} />
  );

  function goPoolHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolPool() {
    navigate("/Havuz");
    setSelectedHome(false);
    setSelectedPool(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolProfile() {
    navigate("/Profil");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function goPoolNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }

  async function changeBankInfo() {
    var data = qs.stringify({
      bank: bankName,
      bank_name_surname: driverName,
      iban: driverIban.length == 24 ? `TR${driverIban}` : driverIban,
    });
    try {
      const newBankAccountInfo = await postRequest(
        END_POINTS.CHANGE_BANK_ACCOUNT_INFO,
        data
      );
      try {
        const driverInformations = await getRequest(END_POINTS.DRIVER_INFO);
        dispatch(setDriverInfo(driverInformations));
        setBankName(driverInformations.bank);
        setDriverName(driverInformations.bank_name_surname);
        setDriverIban(driverInformations.iban);
      } catch (error) {
        console.log("getDriverInfo, bankAccount error: ", error);
      }
      setInputDisabled(true);
    } catch (error) {
      console.log("changeBankInfo function error: ", error);
    }
  }

  return (
    <div className="d-flex container-fluid flex-column">
      <div className="home-black-header"></div>
      <Toaster />
      <div className="pool-profile-screen">
        <div className="text-center" onClick={() => navigate("/Profil")}>
          <div className="ps-3" style={{ position: "absolute" }}>
            {leftArrow}
          </div>
          <span className="title-header-txt">Banka Hesap Bilgilerim</span>
        </div>
        <form className="bank-account-container">
          <div className="bank-account-info">
            <span>
              Aşağıdaki IBAN numarası kişisel bir hesaba ait olmalıdır.
              Kesinlikle tüzel kişiliğe sahip bir IBAN numarası kullanmayın.
            </span>
          </div>
          <div className="input-group d-flex flex-column p-3">
            <div className="d-flex">
              <span style={styles.titleTxt} className="input-group-text">
                Banka Adı
              </span>
              <input
                style={styles.inputTxt}
                type="text"
                aria-label="First name"
                className="form-control"
                disabled={inputDisabled}
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
            </div>
            <div className="d-flex mt-2">
              <span style={styles.titleTxt} className="input-group-text">
                Ad Soyad
              </span>
              <input
                style={styles.inputTxt}
                type="text"
                aria-label="First name"
                className="form-control"
                disabled={inputDisabled}
                value={driverName}
                onChange={(e) => setDriverName(e.target.value)}
              />
            </div>
            <div className="d-flex mt-2">
              <span style={styles.titleTxt} className="input-group-text">
                IBAN
              </span>
              <input
                style={styles.inputTxt}
                type="text"
                aria-label="First name"
                className="form-control"
                disabled={inputDisabled}
                value={driverIban}
                onChange={(e) => setDriverIban(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary mt-2 col-8 offset-2 fw-semibold"
              onClick={() => {
                if (inputDisabled) {
                  setInputDisabled(false);
                } else {
                  changeBankInfo();
                }
              }}
            >
              {inputDisabled ? "Değiştir" : "Kaydet"}
            </button>
          </div>
        </form>
      </div>
      {inputDisabled && (
        <div>
          <PoolBottomBar
            selectedHome={selectedHome}
            selectedPool={selectedPool}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goPoolHome={goPoolHome}
            goPoolPool={goPoolPool}
            goPoolNotification={goPoolNotification}
            goPoolProfile={goPoolProfile}
          />
        </div>
      )}
    </div>
  );
}

export default BankAccountScreen;

const styles = {
  titleTxt: {
    fontSize: "3.5vw",
    fontWeight: "500",
    color: "black",
    width: "30vw",
    alignItems: "center",
    justifyContent: "center",
  },
  inputTxt: {
    fontSize: "3.5vw",
    color: "black",
  },
};
