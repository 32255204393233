import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "cookies-js";
import persisted from "./persisted/persistedReducers";
import app from "./app/appReducers";

const persistConfig = {
  key: "root",
  storage: new CookieStorage(Cookies, {
    expiration: {
      default: 365 * 86400, // Cookies expire after one year
    },
  }),
};

const rootReducer = combineReducers({
  persisted: persistReducer(persistConfig, persisted),
  app,
});

export const store = createStore(rootReducer);
export const persistor = persistStore(store);

export default store;
