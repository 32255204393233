import {
  SET_AGENCY_TRANSFERS,
  SET_POOL_TRANSFERS,
  SET_TODAY_TRANSFERS,
  SET_TOMORROW_TRANSFERS,
  SET_FUTURE_TRANSFERS,
  SET_PAST_TRANSFERS,
  SET_POOL_TODAY_TRANSFERS,
  SET_POOL_TOMORROW_TRANSFERS,
  SET_POOL_FUTURE_TRANSFERS,
  SET_NOTIFICATION,
} from "./appTypes";

const initialState = {
  agencyTransfers: [],
  poolTransfers: [],
  todayTransfers: [],
  tomorrowTransfers: [],
  futureTransfers: [],
  pastTransfers: [],
  poolTodayTransfers: [],
  poolTomorrowTransfers: [],
  poolFutureTransfers: [],
  notification: {
    title: "",
    body: "",
  },
};

const appReducers = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_AGENCY_TRANSFERS:
      return { ...state, agencyTransfers: action.payload };
    case SET_POOL_TRANSFERS:
      return { ...state, poolTransfers: action.payload };
    case SET_TODAY_TRANSFERS:
      return { ...state, todayTransfers: action.payload };
    case SET_TOMORROW_TRANSFERS:
      return { ...state, tomorrowTransfers: action.payload };
    case SET_FUTURE_TRANSFERS:
      return { ...state, futureTransfers: action.payload };
    case SET_PAST_TRANSFERS:
      return { ...state, pastTransfers: action.payload };
    case SET_POOL_TODAY_TRANSFERS:
      return { ...state, poolTodayTransfers: action.payload };
    case SET_POOL_TOMORROW_TRANSFERS:
      return {
        ...state,
        poolTomorrowTransfers: action.payload,
      };
    case SET_POOL_FUTURE_TRANSFERS:
      return { ...state, poolFutureTransfers: action.payload };
    case SET_NOTIFICATION:
      return { ...state, notification: action.payload };
  }
  return state;
};

export default appReducers;
