import {
  faArrowRightFromBracket,
  faCircleInfo,
  faCircleLeft,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setToken } from "@redux";
import { blackCar, turqoiseCar, yellowCar } from "assets";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./SupervisorHome.css";
import FlatList from "flatlist-react";
import { ref, onValue } from "firebase/database";
import { db } from "services/firebaseConfig";
import axios from "axios";
import { callDrivers } from "services";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

function SupervisorHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.persisted.token);
  const [searchparams] = useSearchParams();
  const queueTitle = searchparams.get("queueTitle");
  const queueName = searchparams.get("queueName");
  const [selectedId, setSelectedId] = useState(""); //driver
  const [openInfoBox, setOpenInfoBox] = useState(false);

  const [drivers, setDrivers] = useState([]);
  const [number, setNumber] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [queueCount, setQueueCount] = useState(0);
  const [calledCount, setCalledCount] = useState(0);

  const [penaltyList, setPenaltyList] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedPenalty, setSelectedPenalty] = useState({});

  function getPenaltyList() {
    var config = {
      method: "get",
      url: "https://api.sawkoop.com/api/penalty",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPenaltyList(response.data.penalty);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getUserInfo();
    getPenaltyList();
    setNumber("10");
    onValue(ref(db, queueName), (snapshot) => {
      const driverData = snapshot.val();
      if (driverData) {
        const driverDataArr = Object.values(driverData);
        setDrivers(driverDataArr);
        setQueueCount(
          driverDataArr.filter((f) => {
            return f.event_id == 2;
          })
        );
        setCalledCount(
          driverDataArr.filter((f) => {
            return f.event_id == 4;
          })
        );
      } else {
        setDrivers([]);
        setCalledCount([]);
        setQueueCount([]);
      }
    });
  }, []);

  function getUserInfo() {
    var config = {
      method: "get",
      url: "https://api.govip.com.tr/api/users/user",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setUserInfo(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const ExitIcon = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faArrowRightFromBracket}
      color={"black"}
      size={"1x"}
    />
  );
  const QueueExitIcon = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faArrowRightFromBracket}
      color={"black"}
      size={"1x"}
    />
  );
  const InfoIcon = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faCircleInfo}
      color={"black"}
      size={"1x"}
    />
  );

  const CloseIcon = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faCircleXmark}
      color={"black"}
      size={"lg"}
    />
  );

  const RenderCalendar = () => {
    return (
      <div className="infobox-container col-10 offset-1 rounded shadow mt-5">
        <Calendar
          selectRange={false}
          returnValue="start"
          onChange={(singleDate) => {
            console.log(
              "selected date => ",
              moment(singleDate)?.format("DD-MM-YYYY")
            );
            setSelectedDate(moment(singleDate)?.format("DD-MM-YYYY"));
          }}
        />
        <div className="d-flex justify-content-around border-end border-bottom border-start border-1 border-secondary">
          <button
            type="button"
            className="btn btn-primary my-2 px-4"
            onClick={() => {
              setOpenCalendar(false);
              setSelectedDate("");
              setSelectedPenalty({});
            }}
          >
            İptal
          </button>
          <button
            disabled={selectedDate !== "" ? false : true}
            type="button"
            className={
              selectedDate !== ""
                ? "btn btn-primary my-2 px-4"
                : "btn btn-outline-dark my-2 px-4"
            }
            onClick={() => {
              setOpenCalendar(false);
            }}
          >
            Seç
          </button>
        </div>
      </div>
    );
  };

  function handleInfoBtn(id) {
    setSelectedId(id);
    setOpenInfoBox(true);
  }

  function closeInfoBox() {
    setOpenInfoBox(false);
    setSelectedPenalty({});
    setSelectedDate({});
  }

  function onPunish() {
    var qs = require("qs");
    if (selectedPenalty.type == 1 && selectedDate == "") {
      setOpenCalendar(true);
    }
    let data = qs.stringify({
      driver_id: selectedId,
      penalty_id: selectedPenalty.id,
      start_date:
        selectedDate == "" ? moment().format("DD-MM-YYYY") : selectedDate,
      user_id: userInfo.id,
    });
    console.log("data => ", data);
    var config = {
      method: "post",
      url: "https://api.sawkoop.com/api/penaltyToDriver",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("response.data.status_code: ", response.data.status_code);
        // response.data => {"status_code": 1, "status_message": "Ceza başarıyla eklendi."}
        setOpenInfoBox(false);
        setSelectedPenalty({});
        setSelectedDate("");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const RenderInfoBox = (item) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (item.item.id == selectedPenalty.id) {
            setSelectedPenalty({});
          } else {
            if (item.item.type == 1) {
              setSelectedDate("");
              setSelectedPenalty(item.item);
              setOpenCalendar(true);
            } else {
              setSelectedPenalty(item.item);
            }
          }
        }}
        className="render-infobox-list "
      >
        <div className="p-1 ps-4 pe-4">
          <div className="d-flex align-items-center justify-content-between border p-3 rounded border-dark">
            <div className="d-flex flex-column">
              <span>{item.item.name}</span>
              {item.item.day > 0 && (
                <span className="penalty-txt">
                  Ceza süresi: {item.item.day} gün
                </span>
              )}
              {item.item.price > 0 && (
                <span className="penalty-txt">
                  Ceza ücreti: {item.item.price} tl
                </span>
              )}
            </div>
            <div>
              <div className="out-circle border border-dark d-flex">
                <div
                  className={
                    item.item.id == selectedPenalty.id
                      ? "selected-inner-circle"
                      : "inner-circle"
                  }
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const InfoBox = () => {
    return (
      <div className="infobox-container col-10 offset-1 rounded shadow mt-5">
        <div
          onClick={() => closeInfoBox()}
          className="d-flex justify-content-end pt-2 pe-2"
        >
          {CloseIcon}
        </div>
        <div className="infobox-flat-div mt-2">
          <FlatList list={penaltyList} renderItem={<RenderInfoBox />} />
        </div>
        <div className="offset-4">
          <button
            type="button"
            className="btn btn-primary fw-bold mt-3 mb-3 col-6"
            onClick={() => {
              onPunish();
            }}
          >
            Ceza Ver
          </button>
        </div>
      </div>
    );
  };

  const RenderDriverList = (item) => {
    return (
      <div className="d-flex align-items-center justify-content-center mt-3">
        <div
          className={
            item.item.event_id == 4
              ? "render-driver-left d-flex col-9 align-items-center p-2 border rounded"
              : "render-driver-exit-left d-flex col-9 align-items-center p-2 border rounded border-dark shadow-sm"
          }
        >
          <div className="col-3">
            <img src={item.item.car.image} className="render-driver-car-img" />
          </div>
          <div className="col-8 ms-1">
            <div>
              <span className="fw-bold render-driver-name-txt">
                {item.item.driver.name}
              </span>
              <span className="ms-1 fw-bold render-driver-name-txt">
                {item.item.driver.surname}
              </span>
            </div>
            <span className="render-driver-plate-txt fw-semibold">
              {item.item.plate}
            </span>
          </div>
          <div>{QueueExitIcon}</div>
        </div>
        <div
          className="ms-3"
          onClick={() => {
            handleInfoBtn(item.item.driver.id);
          }}
        >
          {InfoIcon}
        </div>
      </div>
    );
  };

  const BackIcon = (
    <FontAwesomeIcon icon={faCircleLeft} color="black" size={"xl"} />
  );

  return (
    <div className="supervisor">
      <div className="supervisor-header"></div>
      <div
        onClick={() => navigate(-1)}
        className="d-flex align-items-center mt-1 ms-2"
      >
        <div>{BackIcon}</div>
        <span className="ms-2" style={{ fontSize: "5vw" }}>
          {queueTitle}
        </span>
      </div>
      <div className="supervisor-header-box d-flex justify-content-around">
        <div className="supervisor-header-container d-flex flex-column align-items-center bg-white border border-1 shadow-sm rounded p-1">
          <span className="supervisor-header-txt">Sırada</span>
          <span className="supervisor-header-number">{queueCount.length}</span>
        </div>
        <div className="supervisor-header-container d-flex flex-column align-items-center bg-white border border-1 shadow-sm rounded p-1">
          <span className="supervisor-header-txt">Çağırılan</span>
          <span className="supervisor-header-number">{calledCount.length}</span>
        </div>
        <div
          className="supervisor-header-txt d-flex align-items-center"
          onClick={() => {
            dispatch(setToken(""));
            navigate("/");
          }}
        >
          <span className="me-2">Çıkış Yap</span>
          {ExitIcon}
        </div>
      </div>
      {openInfoBox && <InfoBox />}
      {openCalendar && <RenderCalendar />}
      <div className="supervisor-input-div d-flex flex-column mt-5 align-items-center">
        <input
          className="supervisor-input border border-1 border-dark rounded text-center fw-bold fs-5"
          defaultValue={10}
          onChange={(e) => setNumber(e.target.value)}
        />
        <button
          type="button"
          className="btn btn-primary fw-bold supervisor-call-btn mt-2"
          onClick={() => {
            callDrivers(number, queueName);
          }}
        >
          Çağır
        </button>
      </div>
      <div className="supervisor-flat-div mt-2">
        <FlatList
          list={drivers}
          renderItem={<RenderDriverList />}
          renderWhenEmpty={() => (
            <div className="d-flex justify-content-center">
              <span className="mt-5">Kuyrukta araç bulunmamakta.</span>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default SupervisorHome;
