import { AgencyBottomBar } from "components";
import React, { useEffect, useState } from "react";
import "./AgencyTransfersScreen.css";
import { useNavigate } from "react-router-dom";
import { greenPriceBg, orangePriceBg } from "assets";
import FlatList from "flatlist-react";
import { getAgencyTransfers, getTransferDetail } from "services";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faCity,
  faClock,
  faLocationDot,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import moment from "moment";
import { Toaster } from "react-hot-toast";

function AgencyTransfersScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedTransfers, setSelectedTransfers] = useState(true);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(false);
  const [day, setDay] = useState("today");
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.persisted.token);
  const pool = useSelector((state) => state.persisted.pool);
  const todayTransfers = useSelector((state) => state.app.todayTransfers);
  const tomorrowTransfers = useSelector((state) => state.app.tomorrowTransfers);
  const futureTransfers = useSelector((state) => state.app.futureTransfers);
  const transferDetail = useSelector((state) => state.persisted.transferDetail);
  const [pastTransfers, setPastTransfers] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(true);
  const [calendarDate, setCalendarDate] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const greenArrow = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faCaretRight}
      color="green"
      size={"2x"}
    />
  );

  const greyArrow = (
    <FontAwesomeIcon
      className="card-grey-arrow"
      icon={faCaretDown}
      color="grey"
      size={"2x"}
    />
  );

  function getPastTransfers() {
    var config = {
      method: "get",
      url: `https://api.govip.com.tr/api/transfers?start_date=${startDate}&end_date=${endDate}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPastTransfers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getAgencyTransfers();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  function goAgencyHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyTransfers() {
    navigate("/Transferlerim");
    setSelectedHome(false);
    setSelectedTransfers(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }
  function goAgencyProfile() {
    navigate("/Profilim");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function handleToday() {
    setDay("today");
    setOpenDetail(false);
    setOpenCalendar(false);
  }
  function handleTomorrow() {
    setDay("tomorrow");
    setOpenDetail(false);
    setOpenCalendar(false);
  }
  function handleFuture() {
    setDay("future");
    setOpenDetail(false);
    setOpenCalendar(false);
  }
  function handlePast() {
    setDay("past");
    setOpenCalendar(true);
    setOpenDetail(false);
  }

  const timeIcon = (
    <FontAwesomeIcon icon={faClock} color={"grey"} size={"1x"} />
  );

  const cityIcon = <FontAwesomeIcon icon={faCity} color={"grey"} size={"1x"} />;

  const locationIcon = (
    <FontAwesomeIcon icon={faLocationDot} color={"grey"} size={"1x"} />
  );

  const uetdsIcon = <FontAwesomeIcon icon={faTag} color={"grey"} size={"1x"} />;

  const [openDetail, setOpenDetail] = useState(false);
  const CompletedTransferDetail = () => {
    return (
      <div
        style={styles.detailScreen}
        className="bg-white col-10 offset-1 pt-2"
      >
        <div className="d-flex align-items-center border-bottom justify-content-between p-1 ps-2">
          <div className="d-flex align-items-center col-6">
            <div>{timeIcon}</div>
            <div className="d-flex flex-column ms-2">
              <span style={styles.detailTitle}>Saat ve Tarih</span>
              <span style={styles.detailTxt}>
                {moment(transferDetail.transfer_date_time).format("YYYY-MM-DD")}
              </span>
              <span style={styles.detailTxt}>
                {transferDetail.transfer_hour}
              </span>
            </div>
          </div>

          <div className="d-flex align-items-center ms-2 col-6">
            <div>{cityIcon}</div>
            <div className="d-flex flex-column ms-2">
              <span style={styles.detailTitle}>Şehir</span>
              <span style={styles.detailTxt}>İstanbul</span>
            </div>
          </div>
        </div>

        <div className="border-bottom p-1 ps-2">
          <div className="d-flex align-items-center">
            <div>{locationIcon}</div>
            <div className="d-flex flex-column ms-2">
              <span style={styles.detailTitle}>Nereden</span>
              <span style={styles.detailTxt}>
                {transferDetail.start_address}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div>{locationIcon}</div>
            <div className="d-flex flex-column ms-2">
              <span style={styles.detailTitle}>Nereye</span>
              <span style={styles.detailTxt}>{transferDetail.end_address}</span>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center border-bottom p-1 ps-2">
          <div>{uetdsIcon}</div>
          <div className="d-flex ms-2">
            <span style={styles.uetdsTitle}>Uetds Kodu: </span>
            <span style={styles.uetdsTxt} className="ms-1">
              {transferDetail.uetds}
            </span>
          </div>
        </div>

        <div className="d-flex flex-column border-bottom p-1 ps-2">
          <span>
            <b>Yolcu Oyları</b>
          </span>
          {transferDetail?.quality_content == "" ||
          transferDetail?.quality_content == [] ? (
            <span style={styles.detailTitle}>
              <i>Bu transfere ait oy bilgisi bulunamadı.</i>
            </span>
          ) : (
            transferDetail?.quality_content?.map((item, index) => (
              <div key={index}>
                <div className="d-flex align-items-center justify-content-around col-12">
                  <div className="d-flex flex-column align-items-center col-5">
                    <span style={styles.voteTitle}>Genel</span>
                    <span style={styles.voteTxt}>{item.general_point}</span>
                  </div>
                  <div className="d-flex flex-column align-items-center col-5">
                    <span style={styles.voteTitle}>Araç</span>
                    <span style={styles.voteTxt}>{item.general_point}</span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-around col-12">
                  <div className="d-flex flex-column align-items-center col-5">
                    <span style={styles.voteTitle}>Sürücü</span>
                    <span style={styles.voteTxt}>{item.general_point}</span>
                  </div>
                  <div className="d-flex flex-column align-items-center col-5">
                    <span style={styles.voteTitle}>Karşılama</span>
                    <span style={styles.voteTxt}>{item.general_point}</span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-around col-12">
                  <div className="d-flex flex-column align-items-center col-5">
                    <span style={styles.voteTitle}>Operasyon</span>
                    <span style={styles.voteTxt}>{item.general_point}</span>
                  </div>
                  <div className="d-flex flex-column align-items-center col-5">
                    <span style={styles.voteTitle}>Ortalama</span>
                    <span style={styles.voteTxt}>{item.general_point}</span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="d-flex flex-column p-1 ps-2">
          <span>
            <b>Yolcu Yorumu</b>
          </span>
          {transferDetail?.quality_content == "" ||
          transferDetail?.quality_content == [] ? (
            <span style={styles.detailTitle}>
              <i>Bu transfere ait bir yorum bulunamadı.</i>
            </span>
          ) : (
            transferDetail?.quality_content?.map((item, index) => (
              <div key={index}>
                <span style={styles.detailTitle}>
                  <i>{item.customer_comment}</i>
                </span>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  const RenderHomeTransfers = (item) => {
    return (
      <>
        <div
          onClick={() => {
            if (
              item.item.driver_status_id == 6 ||
              item.item.driver_status_id == 8
            ) {
              getTransferDetail(item.item.id);
              if (openDetail == true) {
                setOpenDetail(false);
                setSelectedId(item.item.id);
                setTimeout(() => {
                  setSelectedId(item.item.id);
                  if (selectedId == item.item.id) {
                    setOpenDetail(false);
                  } else {
                    setOpenDetail(true);
                  }
                }, 300);
              } else {
                setSelectedId(item.item.id);
                setTimeout(() => {
                  setOpenDetail(true);
                }, 300);
              }
            }
          }}
          className="pt-2 pb-1 d-flex justify-content-center"
        >
          <div className="home-render-container shadow-sm d-flex justify-content-center">
            <div
              className="home-render-status-bar"
              style={{ backgroundColor: item.item.driver_status_color_code }}
            ></div>
            <div className="home-render-transfer-card d-flex flex-column">
              <div className="home-render-card-top d-flex align-items-center justify-content-around">
                <div className="card-short-div">
                  <span className="card-short-txt">
                    {item.item.short_start_address}
                  </span>
                </div>
                <div>{greenArrow}</div>
                <div className="card-short-div">
                  <span className="card-short-txt">
                    {item.item.short_end_address}
                  </span>
                </div>
              </div>
              <div className="home-card-separator"></div>
              {pool ? (
                <div className="home-render-card-bottom d-flex align-items-center ">
                  <div className="card-date-div d-flex flex-column align-items-center">
                    <span className="card-date-txt">
                      {item.item.transfer_date}
                    </span>
                    <span className="card-hour-txt">
                      {item.item.transfer_hour}
                    </span>
                  </div>
                  <div className="card-status-div d-flex flex-column align-items-center">
                    <span
                      className="card-status-txt"
                      style={{
                        color: item.item.driver_status_color_code,
                        fontSize: "small",
                      }}
                    >
                      {item.item.driver_status_title}
                    </span>
                    <div>{greyArrow}</div>
                  </div>
                  <div className="card-price-div d-flex flex-column align-items-center">
                    <img
                      className="card-price-img"
                      src={
                        item.item.driver_status_id == 2 ||
                        item.item.driver_status_id == 6
                          ? greenPriceBg
                          : orangePriceBg
                      }
                    />
                    <span className="card-price-txt">
                      {item.item.price} {item.item.currency}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="home-render-card-bottom d-flex align-items-center justify-content-around">
                  <div className="card-date-div d-flex flex-column align-items-center">
                    <span className="card-date-txt">
                      {item.item.transfer_date}
                    </span>
                    <span className="card-hour-txt">
                      {item.item.transfer_hour}
                    </span>
                  </div>
                  <div className="pt-3 pe-3">{greyArrow}</div>
                  <div className="card-status-div d-flex flex-column align-items-center pb-3">
                    <span
                      className="card-status-txt"
                      style={{
                        color: item.item.driver_status_color_code,
                        fontSize: "small",
                      }}
                    >
                      {item.item.driver_status_title}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {openDetail && item.item.id == selectedId && (
          <CompletedTransferDetail />
        )}
      </>
    );
  };
  return (
    <div className="container-fluid">
      <div className="agency-transfers-header d-flex flex-column justify-content-between">
        <div className="pt-4 ps-5">
          <span className="text-white">
            <b>Transferlerim</b>
          </span>
        </div>
        <div className="time-buttons text-white d-flex justify-content-between align-items-end">
          <div
            onClick={handleToday}
            className="time-button-div d-flex flex-column text-center"
          >
            <span className="time-button-txt">Bugün</span>
            <div
              className={
                day == "today"
                  ? "time-selected-button-line"
                  : "time-button-line"
              }
            ></div>
          </div>
          <div
            onClick={handleTomorrow}
            className="time-button-div d-flex flex-column text-center"
          >
            <span className="time-button-txt">Yarın</span>
            <div
              className={
                day == "tomorrow"
                  ? "time-selected-button-line"
                  : "time-button-line"
              }
            ></div>
          </div>
          <div
            onClick={handleFuture}
            className="time-button-div d-flex flex-column text-center"
          >
            <span className="time-button-txt">Gelecek</span>
            <div
              className={
                day == "future"
                  ? "time-selected-button-line"
                  : "time-button-line"
              }
            ></div>
          </div>
          <div
            onClick={handlePast}
            className="time-button-div d-flex flex-column text-center"
          >
            <span className="time-button-txt">Geçmiş</span>
            <div
              className={
                day == "past" ? "time-selected-button-line" : "time-button-line"
              }
            ></div>
          </div>
        </div>
      </div>
      <Toaster />
      <div className="agency-transfers-body">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center pool-transfers-spinner pool-spinner-div">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : day == "past" && openCalendar ? (
          <div
            className="d-flex justify-content-center align-items-center flex-column"
            style={{ height: "62vh" }}
          >
            <div style={{ height: "50vh" }}>
              <Calendar
                allowPartialRange={true}
                selectRange={true}
                returnValue="range"
                onChange={(range) => {
                  setCalendarDate(range);
                  setStartDate(moment(range[0])?.format("YYYY-MM-DD"));
                  setEndDate(moment(range[1])?.format("YYYY-MM-DD"));
                }}
              />
            </div>
            <div style={{ height: "10vh" }}>
              <button
                disabled={calendarDate.length == 2 ? false : true}
                type="button"
                className={
                  calendarDate.length == 2
                    ? "btn btn-primary mt-2"
                    : "btn btn-outline-dark mt-2"
                }
                onClick={() => {
                  setOpenCalendar(false);
                  setLoading(true);
                  getPastTransfers();
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                }}
              >
                Transferleri Getir
              </button>
            </div>
          </div>
        ) : (
          <div className="agency-transfers-list">
            <FlatList
              list={
                day == "today"
                  ? todayTransfers
                  : day == "tomorrow"
                  ? tomorrowTransfers
                  : day == "future"
                  ? futureTransfers
                  : pastTransfers
              }
              renderItem={<RenderHomeTransfers />}
              renderWhenEmpty={() => (
                <div className="d-flex justify-content-center pt-5">
                  <span>
                    {day == "today"
                      ? "Bugüne ait bir transfer bulunamadı."
                      : day == "tomorrow"
                      ? "Yarına ait bir transfer bulunamadı."
                      : day == "future"
                      ? "Gelecek tarihli bir transfer bulunamadı."
                      : "Seçilen tarihlerde bir transfer bulunamadı."}
                  </span>
                </div>
              )}
            />
          </div>
        )}
      </div>
      <div>
        <AgencyBottomBar
          selectedHome={selectedHome}
          selectedTransfers={selectedTransfers}
          selectedNotification={selectedNotification}
          selectedProfile={selectedProfile}
          goAgencyHome={goAgencyHome}
          goAgencyTransfers={goAgencyTransfers}
          goAgencyNotification={goAgencyNotification}
          goAgencyProfile={goAgencyProfile}
        />
      </div>
    </div>
  );
}

export default AgencyTransfersScreen;

const styles = {
  detailScreen: {
    marginTop: "-1vh",
  },
  separator: {
    backgroundColor: "lightgrey",
    height: "1h",
    width: "100vh",
  },
  detailTitle: {
    fontWeight: "500",
    color: "gray",
    fontSize: "3.5vw",
  },
  detailTxt: {
    fontWeight: "700",
    color: "black",
    fontSize: "3.5vw",
  },
  uetdsTitle: {
    fontWeight: "700",
    color: "black",
    fontSize: "3.5vw",
  },
  uetdsTxt: {
    fontWeight: "500",
    color: "gray",
    fontSize: "3.5vw",
  },
  voteTitle: {
    fontWeight: "500",
    color: "gray",
    fontSize: "4vw",
  },
  voteTxt: {
    fontWeight: "700",
    color: "black",
    fontSize: "4.5vw",
  },
};
