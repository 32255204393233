import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgencyBottomBar, PoolBottomBar } from "components";
import { END_POINTS } from "constants";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDriverInfo, getRequest, postRequest } from "services";
import qs from "qs";
import { Toaster } from "react-hot-toast";

function DefenseDetailScreen() {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const defenseId = searchparams.get("defenseId");
  console.log("DEFENSE ID => ", defenseId);
  const pool = useSelector((state) => state.persisted.pool);
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedPool, setSelectedPool] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(true);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedTransfers, setSelectedTransfers] = useState(false);
  const [defenseDetail, setDefenseDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [defenseAnswer, setDefenseAnswer] = useState("");

  const leftArrow = (
    <FontAwesomeIcon icon={faAngleLeft} color="black" size={"xl"} />
  );

  function goPoolHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolPool() {
    navigate("/Havuz");
    setSelectedHome(false);
    setSelectedPool(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolProfile() {
    navigate("/Profil");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function goPoolNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }

  function goAgencyHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyTransfers() {
    navigate("/Transferlerim");
    setSelectedHome(false);
    setSelectedTransfers(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }
  function goAgencyProfile() {
    navigate("/Profilim");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }

  useEffect(() => {
    getDefenseDetail();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  async function sendDefense() {
    var data = qs.stringify({
      id: defenseId,
      note: defenseAnswer,
    });
    try {
      const newDefenseAnswer = await postRequest(
        END_POINTS.ADD_DEFENSE_ANSWER,
        data
      );
      getDefenseDetail();
      getDriverInfo();
    } catch (error) {
      console.log("sendDefense function error: ", error);
    }
  }

  async function getDefenseDetail() {
    try {
      const defensesDetail = await getRequest(
        `${END_POINTS.DEFENSES_DETAIL}/${defenseId}`
      );
      setDefenseDetail(defensesDetail);
    } catch (error) {
      console.log("getDefensesDetail error: ", error);
    }
  }

  return (
    <div className="d-flex container-fluid flex-column">
      <div className="home-black-header"></div>
      <Toaster />
      <div className="pool-profile-screen">
        <div className="text-center" onClick={() => navigate("/Savunmalarim")}>
          <div className="ps-3" style={{ position: "absolute" }}>
            {leftArrow}
          </div>
          <span className="title-header-txt fw-bold">
            Transfer No: {defenseDetail.transfer_no}
          </span>
        </div>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "75vh" }}
          >
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="d-flex flex-column mt-4">
            <div className="d-flex defense-card mt-1 border">
              <div className="col-3 border-end d-flex flex-column align-items-center point-width">
                <div
                  className="bg-lightgrey text-center fw-semibold"
                  style={{ fontSize: "x-small" }}
                >
                  Genel
                </div>
                <div>{defenseDetail.general_point}</div>
              </div>
              <div className="border-end d-flex flex-column align-items-center point-width">
                <div
                  className="bg-lightgrey text-center fw-semibold"
                  style={{ fontSize: "x-small" }}
                >
                  Araç
                </div>
                <div>{defenseDetail.car_point}</div>
              </div>
              <div className="border-end d-flex flex-column align-items-center point-width">
                <div
                  className="bg-lightgrey text-center fw-semibold"
                  style={{ fontSize: "x-small" }}
                >
                  Sürücü
                </div>
                <div>{defenseDetail.driver_point}</div>
              </div>
              <div className="border-end d-flex flex-column align-items-center point-width">
                <div
                  className="bg-lightgrey text-center fw-semibold"
                  style={{ fontSize: "x-small" }}
                >
                  Karşılama
                </div>
                <div>{defenseDetail.welcome_point}</div>
              </div>
              <div className="border-end d-flex flex-column align-items-center point-width">
                <div
                  className="bg-lightgrey text-center fw-semibold"
                  style={{ fontSize: "x-small" }}
                >
                  Operasyon
                </div>
                <div>{defenseDetail.operation_point}</div>
              </div>
              <div className=" d-flex flex-column align-items-center point-width">
                <div
                  className="bg-lightgrey text-center fw-semibold"
                  style={{ fontSize: "x-small" }}
                >
                  Ortalama
                </div>
                <div>{defenseDetail.total_point}</div>
              </div>
            </div>
            {defenseDetail.defense == null ? (
              <div className="p-2">
                <div className="d-flex flex-column ">
                  <span className="fw-bold mt-3">Müşteri Yorumu:</span>
                  <span
                    className="fw-semibold text-secondary"
                    style={{ fontSize: "smaller" }}
                  >
                    {defenseDetail.note}
                  </span>
                </div>
                <div className="mt-3">
                  <span className="fw-bold">Savunmanız:</span>
                  <div className="input-group">
                    <textarea
                      className="form-control defense-txt-area"
                      aria-label="With textarea"
                      onChange={(e) => setDefenseAnswer(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-primary fw-bold col-10 offset-1 mt-4"
                  onClick={sendDefense}
                >
                  SAVUNMAYI GÖNDER
                </button>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center">
                <span className="fw-bold mt-3">Müşteri Yorumu:</span>
                <span
                  className="fw-semibold text-secondary"
                  style={{ fontSize: "smaller" }}
                >
                  {defenseDetail.note}
                </span>
                <span className="fw-bold mt-1">Savunmanız:</span>
                <span
                  className="fw-semibold text-secondary"
                  style={{ fontSize: "smaller" }}
                >
                  {defenseDetail.defense}
                </span>
                <span className="fw-bold mt-1">Sonuç:</span>
                <span
                  className="fw-semibold text-secondary"
                  style={{ fontSize: "smaller" }}
                >
                  {defenseDetail.result_comment == null
                    ? "Savunma sonucunuz bekleniyor."
                    : defenseDetail.result_comment}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {pool ? (
        <div>
          <PoolBottomBar
            selectedHome={selectedHome}
            selectedPool={selectedPool}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goPoolHome={goPoolHome}
            goPoolPool={goPoolPool}
            goPoolNotification={goPoolNotification}
            goPoolProfile={goPoolProfile}
          />
        </div>
      ) : (
        <div>
          <AgencyBottomBar
            selectedHome={selectedHome}
            selectedTransfers={selectedTransfers}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goAgencyHome={goAgencyHome}
            goAgencyTransfers={goAgencyTransfers}
            goAgencyNotification={goAgencyNotification}
            goAgencyProfile={goAgencyProfile}
          />
        </div>
      )}
    </div>
  );
}

export default DefenseDetailScreen;
