const { innerWidth: width, innerHeight: height } = window;

const ww = (param) => {
  return width * param || width;
};
const wh = (param) => {
  return height * param || height;
};

export { ww, wh };
