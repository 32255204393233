import {
  faHome,
  faCar,
  faUser,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./PoolBottomBar.css";

function PoolBottomBar({
  goPoolHome,
  goPoolPool,
  goPoolProfile,
  goPoolNotification,
  selectedHome,
  selectedPool,
  selectedProfile,
  selectedNotification,
}) {
  const homeIcon = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faHome}
      color={selectedHome ? "white" : "grey"}
      size={selectedHome ? "2xl" : "xl"}
    />
  );
  const poolIcon = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faCar}
      color={selectedPool ? "white" : "grey"}
      size={selectedPool ? "2xl" : "xl"}
    />
  );

  const notificationIcon = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faBell}
      color={selectedNotification ? "white" : "grey"}
      size={selectedNotification ? "2xl" : "xl"}
    />
  );

  const profileIcon = (
    <FontAwesomeIcon
      className="card-green-arrow"
      icon={faUser}
      color={selectedProfile ? "white" : "grey"}
      size={selectedProfile ? "2xl" : "xl"}
    />
  );

  return (
    <div className="pool-bottom-bar-screen d-flex justify-content-center align-items-end fixed-bottom">
      <div className="pool-bottom-bar-container d-flex justify-content-around align-items-center shadow">
        <div
          onClick={goPoolHome}
          className="pool-home-icon d-flex flex-column align-items-center justify-content-center"
        >
          {homeIcon}
          <span
            className={
              selectedHome ? "pool-home-txt-selected" : "pool-home-txt"
            }
          >
            Anasayfa
          </span>
        </div>
        <div
          onClick={goPoolPool}
          className="pool-pool-icon d-flex flex-column align-items-center justify-content-center"
        >
          {poolIcon}
          <span
            className={
              selectedPool ? "pool-pool-txt-selected" : "pool-pool-txt"
            }
          >
            Havuz
          </span>
        </div>
        <div
          onClick={goPoolNotification}
          className="pool-profile-icon d-flex flex-column align-items-center justify-content-center"
        >
          {notificationIcon}
          <span
            className={
              selectedNotification
                ? "pool-profile-txt-selected"
                : "pool-profile-txt"
            }
          >
            Bildirimler
          </span>
        </div>
        <div
          onClick={goPoolProfile}
          className="pool-profile-icon d-flex flex-column align-items-center justify-content-center"
        >
          {profileIcon}
          <span
            className={
              selectedProfile ? "pool-profile-txt-selected" : "pool-profile-txt"
            }
          >
            Profil
          </span>
        </div>
      </div>
    </div>
  );
}

export default PoolBottomBar;
