import "./LoginScreen.css";
import React, { useState } from "react";
import { govipLogo, sawkoopLogo } from "assets";
import { Alert } from "@mui/material";
import { authRequest } from "services";
import { END_POINTS } from "constants";
import qs from "qs";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  setDriverInfo,
  setGroup,
  setHaveCar,
  setPool,
  setToken,
  setType,
  setAuthInfo,
} from "@redux";
import { API_URL } from "constants";
import { ref, set, update } from "firebase/database";
import { db } from "services/firebaseConfig";
import moment from "moment";

//process.env.REACT_APP_COMPANY == "sawkoop" (govip)

function LoginScreen() {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [pass, setPass] = useState("");
  const [emptyInput, setEmptyInput] = useState(false);
  const [incorrectInput, setIncorrectInput] = useState(false);
  const [correctInput, setCorrectInput] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleLogin() {
    setLoading(true);
    if (phone == "" || pass == "") {
      setTimeout(() => {
        setLoading(false);
        setEmptyInput(true);
      }, 1000);
      setTimeout(() => {
        setEmptyInput(false);
      }, 3000);
    } else {
      const authInfo = await authRequest(
        END_POINTS.LOGIN,
        qs.stringify({
          phone: phone,
          apppassword: pass,
        })
      );
      if (authInfo.type == "driver") {
        console.log("type is driver");
        if (authInfo?.status_code == 0) {
          console.log("login status code 0");
          setTimeout(() => {
            setLoading(false);
            setIncorrectInput(true);
          }, 1000);
          setTimeout(() => {
            setIncorrectInput(false);
          }, 3000);
        } else {
          console.log("login status code 1");
          setCorrectInput(true);
          dispatch(setGroup(authInfo.group));
          dispatch(setPool(authInfo.pool));
          dispatch(setType(authInfo.type));
          var config = {
            method: "get",
            url: `${API_URL}${END_POINTS.DRIVER_INFO}`,
            headers: {
              Authorization: `Bearer ${authInfo.token}`,
            },
          };
          axios(config)
            .then(function (response) {
              dispatch(setDriverInfo(response.data));
              update(ref(db, "pudriver/drivers/" + response.data.id), {
                id: response.data.id,
                pool: authInfo.pool,
                group: authInfo.group,
                name: response.data.name,
                surname: response.data.surname,
                plate: response.data.plate,
                avatar: response.data.avatar,
                car_icon: response.data.car_icon,
                status: response.data.status,
                update_time: moment()
                  .locale("tr")
                  .format("YYYY-MM-DD HH:mm:ss"),
              });
              if (authInfo.pool && authInfo.group == 3) {
                dispatch(setHaveCar(false));
              } else {
                dispatch(setHaveCar(true));
              }
              setTimeout(() => {
                setLoading(false);
                setCorrectInput(false);
              }, 1000);
              setTimeout(() => {
                dispatch(setToken(authInfo.token));
              }, 3000);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else {
        console.log("type is user");
        if (authInfo.status_code == 0) {
          console.log("login status code 0");
          setTimeout(() => {
            setLoading(false);
            setIncorrectInput(true);
          }, 1000);
          setTimeout(() => {
            setIncorrectInput(false);
          }, 3000);
        } else {
          console.log("login status code 1");
          setTimeout(() => {
            setCorrectInput(true);
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            dispatch(setType(authInfo.type));
            dispatch(setToken(authInfo.token));
            dispatch(setAuthInfo(authInfo));
          }, 1100);
        }
      }
    }
  }

  return (
    <>
      {emptyInput ? (
        <Alert className="alert-class" severity="error">
          Telefon numarası veya şifre alanı boş bırakılamaz!
        </Alert>
      ) : (
        <></>
      )}
      {incorrectInput ? (
        <Alert className="alert-class" severity="error">
          Hatalı giriş bilgisi!
        </Alert>
      ) : (
        <></>
      )}
      {correctInput ? (
        <Alert className="alert-class" severity="success">
          Giriş işlemi başarılı.
        </Alert>
      ) : (
        <></>
      )}

      <div className="d-flex flex-column justify-content-center login-screen align-items-center">
        {process.env.REACT_APP_COMPANY == "sawkoop" ? (
          <img
            style={{ width: "14.5rem", height: "4.7rem" }}
            src={sawkoopLogo}
          ></img>
        ) : (
          <img style={{ width: "10rem", height: "5rem" }} src={govipLogo}></img>
        )}
        <form>
          <div className="mt-4" style={{ width: "65vw" }}>
            <label htmlFor="exampleInputEmail1" className="form-label">
              Telefon No veya TC Kimlik No:
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={phone}
              // name="phone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="mt-2" style={{ width: "65vw" }}>
            <label htmlFor="exampleInputPassword1" className="form-label">
              Şifre:
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              value={pass}
              // name="pass"
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          <div>
            <button
              style={{ width: "65vw" }}
              type="button"
              className="btn btn-primary mt-3 col-12 d-flex align-items-center justify-content-center"
              onClick={handleLogin}
            >
              Giriş
              {loading && (
                <div className="clearfix">
                  <div
                    className="spinner-border float-end ms-1"
                    style={{ width: "5vw", height: "5vw" }}
                    role="status"
                  ></div>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default LoginScreen;
