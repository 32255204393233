import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { blackCar, turqoiseCar, yellowCar } from "assets";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./ShowQueue.css";

function ShowQueue() {
  const navigate = useNavigate();
  const [myID, setMyID] = useState(2);
  const [myInfo, setMyInfo] = useState([]);
  const [frontInfo, setFrontInfo] = useState([]);
  const [backInfo, setBackInfo] = useState([]);

  const driverList = [
    {
      id: 2,
      name: "Erdem",
      surname: "Yalçın",
      plate: "34ABC5757",
      exit: true,
      car_icon: turqoiseCar,
      queue: 1,
    },
    {
      id: 3,
      name: "Uğur",
      surname: "Kurtuldu",
      plate: "34AB2345",
      exit: false,
      car_icon: blackCar,
      queue: 2,
    },
    {
      id: 4,
      name: "name",
      surname: "surname",
      plate: "plate",
      exit: false,
      car_icon: blackCar,
      queue: 3,
    },
    {
      id: 5,
      name: "name",
      surname: "surname",
      plate: "plate",
      exit: false,
      car_icon: yellowCar,
      queue: 4,
    },
  ];

  function findDriverIndex() {
    const myArr = driverList.filter((f) => {
      return f.id == myID;
    });
    if (myArr.length == 0) {
      console.log("Sürücü kuyrukta değil!");
      setMyInfo(undefined);
    } else {
      console.log("Sürücü kuyrukta!");
      const myIndex = driverList.findIndex((driver) => {
        return driver.id === myID;
      });
      setMyInfo(driverList[myIndex]);
      setFrontInfo(driverList[myIndex - 1]);
      setBackInfo(driverList[myIndex + 1]);
    }
  }

  console.log("me: ", myInfo);
  console.log("front: ", frontInfo);
  console.log("back: ", backInfo);
  // undefined ? 'boş' : 'info'

  useEffect(() => {
    findDriverIndex();
  }, []);

  const BackIcon = (
    <FontAwesomeIcon icon={faCircleLeft} color="black" size={"xl"} />
  );
  return (
    <div className="show-queue-screen">
      <div className="show-queue-black-header"></div>
      <Toaster />
      <div
        onClick={() => navigate(-1)}
        className="d-flex align-items-center mt-1 ms-2"
      >
        <div>{BackIcon}</div>
        <span className="ms-2" style={styles.backTxt}>
          Geri
        </span>
      </div>
      <div className="queue-car-container">
        {/*front car*/}
        {frontInfo !== undefined && myInfo !== undefined ? (
          <div className="d-flex align-items-center justify-content-between col-9 queue-bg-grey rounded p-1 ms-2">
            <div className="col-1" style={styles.carIconDiv}>
              <img style={styles.carIconImg} src={frontInfo.car_icon} />
            </div>
            <div className="d-flex flex-column col-7">
              <span style={styles.nameTxt}>
                {frontInfo.name} {frontInfo.surname}
              </span>
              <span style={styles.plateTxt}>{frontInfo.plate}</span>
            </div>
            <div className="col-1" style={styles.queueTxtDiv}>
              <span>{frontInfo.queue}</span>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-between col-9 queue-bg-grey rounded p-1 ms-2">
            <span style={styles.emptyTxt}>
              Ön sıranızda herhangi bir sürücü bulunamadı.
            </span>
          </div>
        )}
        {/*front car*/}

        {/*my car*/}
        {myInfo !== undefined ? (
          <div className="d-flex align-items-center justify-content-between col-9 bg-white rounded shadow p-3 ms-5 mt-3">
            <div className="col-1" style={styles.carIconDiv}>
              <img style={styles.carIconImg} src={myInfo.car_icon} />
            </div>
            <div className="d-flex flex-column col-7">
              <span style={styles.nameTxt}>
                {myInfo.name} {myInfo.surname}
              </span>
              <span style={styles.plateTxt}>{myInfo.plate}</span>
            </div>
            <div className="col-1" style={styles.myQueueTxtDiv}>
              <span>{myInfo.queue}</span>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-between col-9 bg-white rounded p-4 ms-5 shadow mt-3">
            <span style={styles.emptyTxt}>Sırada değilsiniz.</span>
          </div>
        )}
        {/*my car*/}

        {/*back car*/}
        {backInfo !== undefined && myInfo !== undefined ? (
          <div className="d-flex align-items-center justify-content-between col-9 queue-bg-grey rounded p-1 ms-2 mt-3">
            <div className="col-1" style={styles.carIconDiv}>
              <img style={styles.carIconImg} src={backInfo.car_icon} />
            </div>
            <div className="d-flex flex-column col-7">
              <span style={styles.nameTxt}>
                {backInfo.name} {backInfo.surname}
              </span>
              <span style={styles.plateTxt}>{backInfo.plate}</span>
            </div>
            <div className="col-1" style={styles.queueTxtDiv}>
              <span>{backInfo.queue}</span>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-between col-9 queue-bg-grey rounded p-1 ms-2 mt-3">
            <span style={styles.emptyTxt}>
              Arka sıranızda herhangi bir sürücü bulunamadı.
            </span>
          </div>
        )}
        {/*back car*/}
      </div>
    </div>
  );
}

export default ShowQueue;

const styles = {
  backTxt: {
    fontSize: "5vw",
  },
  emptyTxt: {
    fontSize: "3.5vw",
  },
  carIconDiv: {},
  carIconImg: {
    width: "10vw",
    height: "10vw",
  },
  queueTxtDiv: {
    width: "7vw",
    height: "7vw",
    borderRadius: "3.5vw",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  myQueueTxtDiv: {
    width: "7vw",
    height: "7vw",
    borderRadius: "3.5vw",
    backgroundColor: "rgba(233, 233, 233)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  nameTxt: {
    fontSize: "4.5vw",
    fontWeight: "500",
    color: "black",
  },
  plateTxt: {
    fontSize: "3.5vw",
    fontWeight: "500",
    color: "grey",
  },
};
