import {
  SET_AGENCY_TRANSFERS,
  SET_POOL_TRANSFERS,
  SET_TODAY_TRANSFERS,
  SET_TOMORROW_TRANSFERS,
  SET_FUTURE_TRANSFERS,
  SET_PAST_TRANSFERS,
  SET_POOL_TODAY_TRANSFERS,
  SET_POOL_TOMORROW_TRANSFERS,
  SET_POOL_FUTURE_TRANSFERS,
  SET_NOTIFICATION,
} from "./appTypes";

export const setAgencyTransfers = (agencyTransfers) => ({
  type: SET_AGENCY_TRANSFERS,
  payload: agencyTransfers,
});
export const setPoolTransfers = (poolTransfers) => ({
  type: SET_POOL_TRANSFERS,
  payload: poolTransfers,
});
export const setTodayTransfers = (todayTransfers) => ({
  type: SET_TODAY_TRANSFERS,
  payload: todayTransfers,
});
export const setTomorrowTransfers = (tomorrowTransfers) => ({
  type: SET_TOMORROW_TRANSFERS,
  payload: tomorrowTransfers,
});
export const setFutureTransfers = (futureTransfers) => ({
  type: SET_FUTURE_TRANSFERS,
  payload: futureTransfers,
});
export const setPastTransfers = (pastTransfers) => ({
  type: SET_PAST_TRANSFERS,
  payload: pastTransfers,
});
export const setPoolTodayTransfers = (poolTodayTransfers) => ({
  type: SET_POOL_TODAY_TRANSFERS,
  payload: poolTodayTransfers,
});
export const setPoolTomorrowTransfers = (poolTomorrowTransfers) => ({
  type: SET_POOL_TOMORROW_TRANSFERS,
  payload: poolTomorrowTransfers,
});
export const setPoolFutureTransfers = (poolFutureTransfers) => ({
  type: SET_POOL_FUTURE_TRANSFERS,
  payload: poolFutureTransfers,
});
export const setNotification = (notification) => ({
  type: SET_NOTIFICATION,
  payload: notification,
});
