import { AgencyBottomBar } from "components";
import "./AgencyProfileScreen.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { setDriverInfo, setHaveCar, setToken } from "@redux";
import { driverOffline, postRequest } from "services";
import { Toaster } from "react-hot-toast";
import { END_POINTS } from "constants";
import qs from "qs";

function AgencyProfileScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const group = useSelector((state) => state.persisted.group);
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedTransfers, setSelectedTransfers] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(true);

  function goAgencyHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyTransfers() {
    navigate("/Transferlerim");
    setSelectedHome(false);
    setSelectedTransfers(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyProfile() {
    navigate("/Profilim");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function goAgencyNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }

  const rightArrow = (
    <FontAwesomeIcon icon={faAngleRight} color="black" size={"2x"} />
  );

  return (
    <div className="d-flex container-fluid flex-column">
      <div className="home-black-header"></div>
      <Toaster />
      <div className="pool-profile-screen">
        <div className="text-center">Profil</div>
        <div className="profile-img-container d-flex align-items-center mt-2">
          <div className="d-flex flex-column align-items-center col-5">
            <img className="profile-img" src={driverInfo.avatar}></img>
          </div>
          <div className="d-flex flex-column col-7">
            <span
              className="text-black fw-semibold"
              style={{ fontSize: "4.5vw" }}
            >
              {driverInfo.name} {driverInfo.surname}
            </span>
            <span
              className="text-black fw-semibold"
              style={{ fontSize: "3.5vw" }}
            >
              {driverInfo.phone}
            </span>
            <span
              className="text-secondary fw-semibold"
              style={{ fontSize: "3vw" }}
            >
              {driverInfo.email}
            </span>
          </div>
        </div>
        <div className="profile-options-container d-flex flex-column">
          <div
            className="d-flex align-items-center justify-content-center mt-4"
            onClick={() => navigate("/Savunmalarim")}
          >
            <span className="col-10 ps-5 fw-semibold">Savunmalarım</span>
            <div className="col-2">{rightArrow}</div>
          </div>
          <div className="profile-option-separator m-3"></div>
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => navigate("/Kazanclarim")}
          >
            <span className="col-10 ps-5 fw-semibold">Kazançlarım</span>
            <div className="col-2">{rightArrow}</div>
          </div>
          {/* <div className="profile-option-separator m-3"></div>
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => navigate("/Vardiyalarim")}
          >
            <span className="col-10 ps-5 fw-semibold">Vardiyalarım</span>
            <div className="col-2">{rightArrow}</div>
          </div> */}
        </div>
        <div className="d-flex mt-5 justify-content-center pb-4">
          <button
            type="button"
            className="btn btn-primary fw-bold col-11 py-2"
            onClick={async () => {
              driverOffline();
              var config = qs.stringify({
                fcm_token: "",
              });
              try {
                const deletedFcmToken = await postRequest(
                  END_POINTS.CREATE_FCM_TOKEN,
                  config
                );
                if (group == 3) {
                  dispatch(setHaveCar(false));
                }
                setTimeout(() => {
                  dispatch(setDriverInfo([]));
                  dispatch(setToken(""));
                  navigate("/");
                }, 1000);
              } catch (error) {
                console.log("createFcmToken error: ", error);
              }
            }}
          >
            Çıkış Yap
          </button>
        </div>
      </div>
      <div>
        <AgencyBottomBar
          selectedHome={selectedHome}
          selectedTransfers={selectedTransfers}
          selectedNotification={selectedNotification}
          selectedProfile={selectedProfile}
          goAgencyHome={goAgencyHome}
          goAgencyTransfers={goAgencyTransfers}
          goAgencyNotification={goAgencyNotification}
          goAgencyProfile={goAgencyProfile}
        />
      </div>
    </div>
  );
}

export default AgencyProfileScreen;
