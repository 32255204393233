import { setNotification } from "@redux";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  AgencyAddShiftScreen,
  AgencyHomeScreen,
  AgencyProfileScreen,
  AgencyShiftsScreen,
  AgencyTransfersScreen,
  BankAccountScreen,
  CallPassangerScreen,
  ChooseGate,
  ChooseQueue,
  DefenseDetailScreen,
  DefenseScreen,
  DropOffScreen,
  EarningScreen,
  ExitQueue,
  LoginScreen,
  NotificationScreen,
  PickUpScreen,
  PoolChooseCarScreen,
  PoolHomeScreen,
  PoolProfileScreen,
  PoolTransfersScreen,
  ProfileDetail,
  ShowQueue,
  SplashScreen,
  SupervisorHome,
  TransferCompletedScreen,
  TransferDetailScreen,
} from "screens";
import { getAgencyTransfers, getPoolTransfers, Notify } from "services";
import { getTokenFound, onMessageListener } from "services/firebaseConfig";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.persisted.token);
  const pool = useSelector((state) => state.persisted.pool);
  const type = useSelector((state) => state.persisted.type);
  const haveCar = useSelector((state) => state.persisted.haveCar);
  const notification = useSelector((state) => state.app.notification);
  const [notificationScreen, setNotificationScreen] = useState("");
  const [splash, setSplash] = useState(true);

  // useEffect(() => {
  //   if (notification?.title) {
  //     Notify({ goNotificationScreen }, notificationScreen);
  //   }
  // }, [notification]);

  getTokenFound();
  onMessageListener()
    .then((payload) => {
      console.log("payload notification =>>>>> ", payload?.notification);
      console.log("payload data screen =>>>>> ", payload?.data?.screen);
      setNotificationScreen(payload?.data?.screen);
      dispatch(
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        })
      );

      Notify({ goHome, goPool, goExit, goTest }, payload?.data?.screen);
    })
    .catch((err) => console.log("failed: ", err));

  function goHome() {
    navigate("/");
    getAgencyTransfers();
  }
  function goPool() {
    navigate("/Havuz");
    getPoolTransfers();
  }
  function goExit() {
    navigate("/Kuyruk-Cikis");
  }
  function goTest() {
    console.log("test notification");
  }

  return !token ? (
    // AUTH ROUTES
    <Routes>
      <Route path="/" element={<LoginScreen />} />
    </Routes>
  ) : type == "user" ? (
    // SUPERVISOR HOME
    <Routes>
      {/* <Route path="/" element={<ChooseGate />} /> */}
      <Route path="/" element={<ChooseQueue />} />
      <Route path="/Arac-Cagirma" element={<SupervisorHome />} />
    </Routes>
  ) : !pool ? (
    // AGENCY ROUTES
    <Routes>
      <Route path="/" element={<AgencyHomeScreen />} />
      <Route path="/Transferlerim" element={<AgencyTransfersScreen />} />
      <Route path="/Transfer-Detayi" element={<TransferDetailScreen />} />
      <Route path="/Yolcu-Alis-Noktasi" element={<PickUpScreen />} />
      <Route
        path="/Yolcu-Alindi-Veya-Bulunamadi"
        element={<CallPassangerScreen />}
      />
      <Route path="/Yolcu-Birakis-Noktasi" element={<DropOffScreen />} />
      <Route
        path="/Transfer-Tamamlandi"
        element={<TransferCompletedScreen />}
      />
      <Route path="/Profilim" element={<AgencyProfileScreen />} />
      <Route path="/Profil-Detay" element={<ProfileDetail />} />
      <Route path="/Savunmalarim" element={<DefenseScreen />} />
      <Route path="/Savunma-Detayi" element={<DefenseDetailScreen />} />
      <Route path="/Bildirimler" element={<NotificationScreen />} />
      <Route path="/Kazanclarim" element={<EarningScreen />} />
      <Route path="/Vardiyalarim" element={<AgencyShiftsScreen />} />
      <Route path="/Mesai-Ekle" element={<AgencyAddShiftScreen />} />
    </Routes>
  ) : haveCar ? (
    // POOL ROUTES
    <Routes>
      <Route path="/" element={<PoolHomeScreen />} />
      <Route path="/Havuz" element={<PoolTransfersScreen />} />
      <Route path="/Transfer-Detayi" element={<TransferDetailScreen />} />
      <Route path="/Yolcu-Alis-Noktasi" element={<PickUpScreen />} />
      <Route
        path="/Yolcu-Alindi-Veya-Bulunamadi"
        element={<CallPassangerScreen />}
      />
      <Route path="/Yolcu-Birakis-Noktasi" element={<DropOffScreen />} />
      <Route
        path="/Transfer-Tamamlandi"
        element={<TransferCompletedScreen />}
      />
      <Route path="/Profil" element={<PoolProfileScreen />} />
      <Route path="/Profil-Detay" element={<ProfileDetail />} />
      <Route path="/Savunmalarim" element={<DefenseScreen />} />
      <Route path="/Savunma-Detayi" element={<DefenseDetailScreen />} />
      <Route path="/Bildirimler" element={<NotificationScreen />} />
      <Route path="/Kazanclarim" element={<EarningScreen />} />
      <Route path="/Banka-Hesap-Bilgilerim" element={<BankAccountScreen />} />
      <Route path="/Kuyruk" element={<ShowQueue />} />
      <Route path="/Kuyruk-Cikis" element={<ExitQueue />} />
    </Routes>
  ) : (
    // POOL - SELECT CAR SCREEN
    <Routes>
      <Route path="/" element={<PoolChooseCarScreen />} />
    </Routes>
  );
}

export default App;
