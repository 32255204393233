import {
  SET_TOKEN,
  SET_POOL,
  SET_GROUP,
  SET_TYPE,
  SET_HAVE_CAR,
  SET_DRIVERINFO,
  SET_TRANSFER_ID,
  SET_TRANSFERDETAIL,
  TRANSFER_DETAIL_TIME,
  PICK_UP_TIME,
  CALL_PASSENGER_TIME,
  AUTH_INFO,
  FCM_TOKEN,
  DRIVER_LOCATION,
} from "./persistedTypes";

export const setToken = (token) => ({ type: SET_TOKEN, payload: token });
export const setPool = (pool) => ({ type: SET_POOL, payload: pool });
export const setGroup = (group) => ({ type: SET_GROUP, payload: group });
export const setType = (type) => ({ type: SET_TYPE, payload: type });
export const setHaveCar = (haveCar) => ({
  type: SET_HAVE_CAR,
  payload: haveCar,
});
export const setDriverInfo = (driverInfo) => ({
  type: SET_DRIVERINFO,
  payload: driverInfo,
});
export const setTransferId = (transferId) => ({
  type: SET_TRANSFER_ID,
  payload: transferId,
});
export const setTransferDetail = (transferDetail) => ({
  type: SET_TRANSFERDETAIL,
  payload: transferDetail,
});
export const setTransferDetailTime = (transferDetailTime) => ({
  type: TRANSFER_DETAIL_TIME,
  payload: transferDetailTime,
});
export const setPickUpTime = (pickUpTime) => ({
  type: PICK_UP_TIME,
  payload: pickUpTime,
});
export const setCallPassengerTime = (callPassengerTime) => ({
  type: CALL_PASSENGER_TIME,
  payload: callPassengerTime,
});
export const setAuthInfo = (authInfo) => ({
  type: AUTH_INFO,
  payload: authInfo,
});
export const setFcmToken = (fcmToken) => ({
  type: FCM_TOKEN,
  payload: fcmToken,
});
export const setDriverLocation = (driverLocation) => ({
  type: DRIVER_LOCATION,
  payload: driverLocation,
});
