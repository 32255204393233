import { AgencyBottomBar, PoolBottomBar } from "components";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import "./NotificationScreen.css";
import PullToRefresh from "react-simple-pull-to-refresh";
import FlatList from "flatlist-react";
import { getRequest } from "services";
import { END_POINTS } from "constants";
import moment from "moment";

function NotificationScreen() {
  const navigate = useNavigate();
  const pool = useSelector((state) => state.persisted.pool);
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedPool, setSelectedPool] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(true);
  const [selectedTransfers, setSelectedTransfers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [placeholderData, setPlaceholderData] = useState([
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ]);

  const RenderPlaceholders = () => {
    return (
      <div
        style={styles.placeholder}
        className="rounded p-2 m-3 mt-3 d-flex justify-content-center flex-column"
      >
        <p class="placeholder-wave">
          <span class="placeholder col-9"></span>
        </p>
        <p style={styles.placeMargin} class="placeholder-wave">
          <span class="placeholder col-11"></span>
        </p>
        <p style={styles.placeMargin} class="placeholder-wave">
          <span class="placeholder col-7"></span>
        </p>
      </div>
    );
  };

  const RenderNotifications = (item) => {
    return (
      <div
        onClick={() => {
          console.log("NOTIFICATION TYPE => ", item.item.type);
          if (
            item.item.type == "newDefense" ||
            item.item.type == "defenseResult"
          ) {
            navigate({
              pathname: "/Savunma-Detayi",
              search: createSearchParams({
                defenseId: item.item.record_id,
              }).toString(),
            });
          } else if (item.item.type == "exitFromQeueu") {
            const now = moment();
            const notificationTime = moment(item.item.send_date);
            const clickDiff = now.diff(notificationTime, "minutes");
            console.log("clickDiff: ", clickDiff);
            if (clickDiff < 16) {
              navigate("/Kuyruk-Cikis");
            }
          } else if (item.item.type == "poolNewTransfer") {
            navigate("/Havuz");
          } else if (item.item.type == "newTransfer") {
            navigate("/");
          }
        }}
        className="rounded p-2 m-3 mt-3 d-flex justify-content-center flex-column shadow-sm"
        style={styles.renderScreen}
      >
        <div style={styles.renderTitleTxt}>
          <span>{item.item.title}</span>
        </div>
        <div style={styles.renderBodyTxt}>
          <span>{item.item.message}</span>
        </div>
        {/* {moment().diff(item.item.send_date, "minutes") < 16 && (
          <div>
            <span style={styles.warnTxt}>Acil çıkış yapmak için tıklayın.</span>
          </div>
        )} */}
        <div
          style={styles.renderDateTxt}
          className="d-flex justify-content-end mt-1"
        >
          <span>
            {moment(item.item.send_date)
              .locale("tr")
              .format("YYYY-MM-DD / HH:mm:ss")}
          </span>
        </div>
      </div>
    );
  };

  const styles = {
    screen: {
      backgroundColor: "#f5f5f5",
      overflow: "scroll",
      height: "80vh",
    },
    placeholder: {
      backgroundColor: "white",
    },
    placeMargin: {
      marginTop: "-2.5vh",
    },
    renderScreen: {
      backgroundColor: "white",
    },
    renderTitleTxt: {
      fontSize: "4vw",
      color: "black",
      fontWeight: "600",
    },
    renderBodyTxt: {
      fontSize: "3.5vw",
      color: "black",
    },
    renderDateTxt: {
      fontSize: "2.5vw",
      color: "black",
      fontStyle: "italic",
    },
    warnTxt: {
      fontSize: "2.8vw",
      color: "red",
      fontWeight: "bold",
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    getNotifications();
  }, []);

  async function getNotifications() {
    try {
      const driverNotifications = await getRequest(END_POINTS.NOTIFICATIONS);
      setNotifications(driverNotifications);
    } catch (error) {
      console.log("getDriverNotifications error: ", error);
    }
  }

  function goPoolHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolPool() {
    navigate("/Havuz");
    setSelectedHome(false);
    setSelectedPool(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolProfile() {
    navigate("/Profil");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function goPoolNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }

  function goAgencyHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyTransfers() {
    navigate("/Transferlerim");
    setSelectedHome(false);
    setSelectedTransfers(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }
  function goAgencyProfile() {
    navigate("/Profilim");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }

  return (
    <>
      <div className="d-flex container-fluid flex-column">
        <div className="home-black-header"></div>
        <Toaster />
        <div style={styles.screen}>
          {loading ? (
            <div>
              <FlatList
                list={placeholderData}
                renderItem={<RenderPlaceholders />}
              />
            </div>
          ) : (
            <div>
              <FlatList
                list={notifications}
                renderItem={<RenderNotifications />}
              />
            </div>
          )}
        </div>
      </div>
      {pool ? (
        <div>
          <PoolBottomBar
            selectedHome={selectedHome}
            selectedPool={selectedPool}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goPoolHome={goPoolHome}
            goPoolPool={goPoolPool}
            goPoolNotification={goPoolNotification}
            goPoolProfile={goPoolProfile}
          />
        </div>
      ) : (
        <div>
          <AgencyBottomBar
            selectedHome={selectedHome}
            selectedTransfers={selectedTransfers}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goAgencyHome={goAgencyHome}
            goAgencyTransfers={goAgencyTransfers}
            goAgencyNotification={goAgencyNotification}
            goAgencyProfile={goAgencyProfile}
          />
        </div>
      )}
    </>
  );
}

export default NotificationScreen;
