import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgencyBottomBar, PoolBottomBar } from "components";
import { END_POINTS } from "constants";
import FlatList from "flatlist-react";
import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getRequest } from "services";
import "./DefenseScreen.css";

function DefenseScreen() {
  const navigate = useNavigate();
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const group = useSelector((state) => state.persisted.group);
  const pool = useSelector((state) => state.persisted.pool);
  const [selectedHome, setSelectedHome] = useState(false);
  const [selectedPool, setSelectedPool] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(true);
  const [selectedTransfers, setSelectedTransfers] = useState(false);
  const [defenses, setDefenses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDefenses();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  async function getDefenses() {
    try {
      const allDefenses = await getRequest(END_POINTS.DEFENSES);
      //   console.log("defenses: ", allDefenses);
      setDefenses(allDefenses);
    } catch (error) {
      console.log("getDefenses function error: ", error);
    }
  }

  const leftArrow = (
    <FontAwesomeIcon icon={faAngleLeft} color="black" size={"xl"} />
  );

  function goPoolHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolPool() {
    navigate("/Havuz");
    setSelectedHome(false);
    setSelectedPool(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goPoolProfile() {
    navigate("/Profil");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }
  function goPoolNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedPool(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }

  function goAgencyHome() {
    navigate("/");
    setSelectedHome(true);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyTransfers() {
    navigate("/Transferlerim");
    setSelectedHome(false);
    setSelectedTransfers(true);
    setSelectedNotification(false);
    setSelectedProfile(false);
  }
  function goAgencyNotification() {
    navigate("/Bildirimler");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(true);
    setSelectedProfile(false);
  }
  function goAgencyProfile() {
    navigate("/Profilim");
    setSelectedHome(false);
    setSelectedTransfers(false);
    setSelectedNotification(false);
    setSelectedProfile(true);
  }

  const RenderDefenses = (item) => {
    return (
      <div
        onClick={() =>
          navigate({
            pathname: "/Savunma-Detayi",
            search: createSearchParams({
              defenseId: item.item.id,
            }).toString(),
          })
        }
        className="mt-3 d-flex flex-column align-items-center"
      >
        <div>
          <span style={{ fontSize: "small" }}>
            Transfer No: <b>{item.item.transfer_no}</b>
          </span>
        </div>
        <div className="defense-card mt-1 border">
          <div className=" d-flex ">
            <div className="col-3 border-end d-flex flex-column align-items-center point-width">
              <div
                className="bg-lightgrey text-center fw-semibold"
                style={{ fontSize: "x-small" }}
              >
                Genel
              </div>
              <div>{item.item.general_point}</div>
            </div>
            <div className="border-end d-flex flex-column align-items-center point-width">
              <div
                className="bg-lightgrey text-center fw-semibold"
                style={{ fontSize: "x-small" }}
              >
                Araç
              </div>
              <div>{item.item.car_point}</div>
            </div>
            <div className="border-end d-flex flex-column align-items-center point-width">
              <div
                className="bg-lightgrey text-center fw-semibold"
                style={{ fontSize: "x-small" }}
              >
                Sürücü
              </div>
              <div>{item.item.driver_point}</div>
            </div>
            <div className="border-end d-flex flex-column align-items-center point-width">
              <div
                className="bg-lightgrey text-center fw-semibold"
                style={{ fontSize: "x-small" }}
              >
                Karşılama
              </div>
              <div>{item.item.welcome_point}</div>
            </div>
            <div className="border-end d-flex flex-column align-items-center point-width">
              <div
                className="bg-lightgrey text-center fw-semibold"
                style={{ fontSize: "x-small" }}
              >
                Operasyon
              </div>
              <div>{item.item.operation_point}</div>
            </div>
            <div className=" d-flex flex-column align-items-center point-width">
              <div
                className="bg-lightgrey text-center fw-semibold"
                style={{ fontSize: "x-small" }}
              >
                Ortalama
              </div>
              <div>{item.item.total_point}</div>
            </div>
          </div>
          <div className="d-flex border-top align-items-center justify-content-between">
            <div
              className="fw-semibold col-3 ps-3"
              style={{ fontSize: "smaller" }}
            >
              Savunma Durumu:{" "}
            </div>
            <div
              className="offset-2 col-7 text-secondary"
              style={{ fontSize: "smaller" }}
            >
              {item.item.defense == null
                ? "Savunmanız bekleniyor"
                : item.item.defense != null && item.item.result_comment == null
                ? "Savunma sonucunuz bekleniyor."
                : "Savunma tamamlandı."}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex container-fluid flex-column">
      <div className="home-black-header"></div>
      <Toaster />
      <div className="pool-profile-screen">
        <div
          className="text-center"
          onClick={() => (pool ? navigate("/Profil") : navigate("/Profilim"))}
        >
          <div className="ps-3" style={{ position: "absolute" }}>
            {leftArrow}
          </div>
          <span className="title-header-txt">Savunmalarım</span>
        </div>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "75vh" }}
          >
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="defenses-flat-list">
            <FlatList
              list={defenses}
              renderItem={<RenderDefenses />}
              renderWhenEmpty={() => (
                <div className="d-flex justify-content-center pt-5">
                  <span>Hiç savunma kaydınız bulunmamaktadır.</span>
                </div>
              )}
            />
          </div>
        )}
      </div>
      {pool ? (
        <div>
          <PoolBottomBar
            selectedHome={selectedHome}
            selectedPool={selectedPool}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goPoolHome={goPoolHome}
            goPoolPool={goPoolPool}
            goPoolNotification={goPoolNotification}
            goPoolProfile={goPoolProfile}
          />
        </div>
      ) : (
        <div>
          <AgencyBottomBar
            selectedHome={selectedHome}
            selectedTransfers={selectedTransfers}
            selectedNotification={selectedNotification}
            selectedProfile={selectedProfile}
            goAgencyHome={goAgencyHome}
            goAgencyTransfers={goAgencyTransfers}
            goAgencyNotification={goAgencyNotification}
            goAgencyProfile={goAgencyProfile}
          />
        </div>
      )}
    </div>
  );
}

export default DefenseScreen;
