import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { driverOffline, driverOnline, postRequestSaw } from "services";
import "./ExitQueue.css";
import Switch from "react-switch";
import StarRatings from "react-star-ratings";
import { PoolBottomBar } from "components";
import { END_POINTS } from "constants";
import qs from "qs";

function ExitQueue() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const driverInfo = useSelector((state) => state.persisted.driverInfo);

  return (
    <div className="show-queue-screen">
      <div className="home-black-header"></div>
      <div className="home-container d-flex justify-content-center flex-column">
        <div className="d-flex align-items-center mt-2 justify-content-around">
          <div className="home-avatar-container d-flex flex-column align-items-center">
            <div
              className="home-avatar-point"
              style={
                driverInfo.online
                  ? { backgroundColor: "#47a716" }
                  : { backgroundColor: "grey" }
              }
            ></div>
            <img className="home-avatar rounded" src={driverInfo.avatar} />
            <div className="mb-1">
              <StarRatings
                rating={
                  Number(driverInfo.driver_point) < 5
                    ? Number(driverInfo.driver_point)
                    : 5
                }
                starRatedColor="#ffd700"
                numberOfStars={5}
                starDimension="20"
                starSpacing="0"
              />
            </div>
          </div>
          <div className="home-txt-container d-flex flex-column align-items-center ms-3">
            <div className="d-flex align-items-center">
              <Switch
                onChange={() => {
                  if (driverInfo.online == 1) {
                    driverOffline();
                  } else {
                    driverOnline();
                  }
                }}
                checked={driverInfo.online == 1 ? true : false}
                onColor="#47a716"
                uncheckedIcon={false}
                checkedIcon={false}
              />
              <span className="switch-txt ms-1">
                {driverInfo.online == 1 ? "Online" : "Offline"}
              </span>
            </div>
            <div>
              <span className="home-name-txt">
                {driverInfo.name} {driverInfo.surname}
              </span>
            </div>
            <div>
              <span className="home-plate-txt">{driverInfo.plate}</span>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.whiteSeparator}></div>
      <div className="exit-queue-screen">
        <div
          style={styles.container}
          className="d-flex flex-column align-items-center"
        >
          <span style={styles.infoTxt1}>Çıkış için çağırıldınız.</span>
          <span style={styles.infoTxt2}>Acil çıkış yapın.</span>
          <button
            style={styles.btnTxt}
            type="button"
            className="btn btn-primary mt-3 col-9 p-2"
            onClick={async () => {
              var config = qs.stringify({
                car_id: driverInfo.car_id,
                driver_id: driverInfo.id,
              });
              try {
                const exitQueue = await postRequestSaw(
                  END_POINTS.EXIT_QUEUE,
                  config
                );
                console.log("exitQueue: ", exitQueue.status);
                if (exitQueue.status !== 1) {
                  console.log("Çıkış işleminde hata oluştu");
                } else {
                  navigate("/");
                }
              } catch (error) {
                console.log("getExitQueue api function error: ", error);
              }
            }}
          >
            Çıkış Yaptım
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExitQueue;

const styles = {
  whiteSeparator: {
    backgroundColor: "white",
    width: "100vw",
    height: "1.5vh",
  },
  container: {
    height: "70vh",
    justifyContent: "center",
  },
  infoTxt1: {
    fontSize: "3.5vw",
    color: "black",
  },
  infoTxt2: {
    fontSize: "4vw",
    color: "black",
    fontWeight: "600",
  },
  btnTxt: {
    fontSize: "3.5vw",
    color: "white",
  },
};
