import React from "react";
import "./TransferCompletedScreen.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Toaster } from "react-hot-toast";

function TransferCompletedScreen() {
  const navigate = useNavigate();

  const element = (
    <FontAwesomeIcon className="check-icon" icon={faCheck} color="green" />
  );

  function goHome() {
    navigate("/");
  }
  return (
    <div className="screen container-fluid">
      <div className="black-header">
        <Toaster />
        <div className="d-flex flex-column justify-content-end align-items-center">
          <span className="black-header-txt">Alış Yeri</span>
          <div className="black-underline"></div>
        </div>
        <div className="d-flex flex-column justify-content-end align-items-center">
          <span className="black-header-txt">Bırakış Yeri</span>
          <div className="white-underline"></div>
        </div>
      </div>
      <div className="justify-content-center d-flex flex-column align-items-center">
        <div className="title-txt-div">
          <span className="title-txt">Yolculuk Tamamlandı</span>
        </div>
        <div className="check-icon">{element}</div>
        <div className="">
          <button
            type="button"
            className="btn btn-primary completed-btn"
            onClick={goHome}
          >
            Anasayfa
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransferCompletedScreen;
