const carIcon = require("./images/carIcon.png");
const driverIcon = require("./images/driverIcon.png");
const govipLogo = require("./images/govipLogo.png");
const greenPriceBg = require("./images/greenPriceBg.png");
const mapTransferBg = require("./images/mapTransferBg-1.png");
const noAvatar = require("./images/noAvatar.png");
const orangePriceBg = require("./images/orangePriceBg.png");
const sawkoopLogo = require("./images/sawkoopLogo.png");
const transferIcon = require("./images/transferIcon.png");
const blackCar = require("./images/blackCar.png");
const turqoiseCar = require("./images/turqoiseCar.png");
const yellowCar = require("./images/yellowCar.png");

export {
  carIcon,
  driverIcon,
  govipLogo,
  greenPriceBg,
  mapTransferBg,
  noAvatar,
  orangePriceBg,
  sawkoopLogo,
  transferIcon,
  blackCar,
  turqoiseCar,
  yellowCar,
};
