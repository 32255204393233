import React, { useEffect, useState } from "react";
import axios from "axios";
import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "constants";
import { END_POINTS } from "constants";
import FlatList from "flatlist-react";
import "./PoolChooseCarScreen.css";
import { faCar, faCircleLeft, faTaxi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-popup-alert";
import "react-popup-alert/dist/index.css";
import { setDriverInfo, setHaveCar, setToken } from "@redux";
import { getDriverInfo } from "services";
import moment from "moment";
import { ref, update } from "firebase/database";
import { db } from "services/firebaseConfig";

function PoolChooseCarScreen() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.persisted.token);
  const [cars, setCars] = useState([]);
  const [selectedCarId, setSelectedCarId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showEmptyAlert, setShowEmptyAlert] = useState(false);

  const BackIcon = (
    <FontAwesomeIcon icon={faCircleLeft} color="black" size={"xl"} />
  );

  function getDriverCars() {
    var config = {
      method: "get",
      url: `${API_URL}${END_POINTS.CARS}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setCars(response.data);
        setCarList(response.data);
        if (response.data.length == 0 || response.data == "Araç bulunamadı!") {
          setShowEmptyAlert(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function selectCar() {
    var data = qs.stringify({
      car_id: selectedCarId,
    });
    var config = {
      method: "post",
      url: `${API_URL}${END_POINTS.SELECT_CAR}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        var config = {
          method: "get",
          url: `${API_URL}${END_POINTS.DRIVER_INFO}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        axios(config)
          .then(function (response) {
            update(ref(db, "pudriver/drivers/" + response.data.id), {
              plate: response.data.plate,
              update_time: moment().locale("tr").format("YYYY-MM-DD HH:mm:ss"),
            });
            dispatch(setHaveCar(true));
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [carList, setCarList] = useState([]);
  const [search, setSearch] = useState("");
  const searchFilter = (text) => {
    if (text) {
      const newData = cars.filter(function (item) {
        const plateData = item.plate
          ? item.plate.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return plateData.indexOf(textData) > -1;
      });
      setCarList(newData);
      setSearch(text);
    } else {
      setCarList(cars);
      setSearch(text);
    }
  };

  useEffect(() => {
    getDriverCars();
  }, []);

  const carIcon = <FontAwesomeIcon icon={faTaxi} color={"black"} size={"xl"} />;

  const RenderCars = (item) => {
    return (
      <div
        onClick={() => {
          setShowAlert(true);
          setSelectedCarId(item.item.id);
        }}
        className="d-flex align-items-center justify-content-between p-3 bg-white border border-1  shadow-sm col-10 offset-1 rounded mb-4 render-car-list"
      >
        <div className="car-icon-div">{carIcon}</div>
        <div className="d-flex flex-column col-8">
          <span className="render-plate-txt">{item.item.plate}</span>
          <span className="render-brand-txt">{item.item.brand}</span>
        </div>
        {selectedCarId == item.item.id ? (
          <div className="default-check border border-dark border-1 d-flex justify-content-center align-items-center">
            <div className="selected-check"></div>
          </div>
        ) : (
          <div className="default-check border border-dark border-1"></div>
        )}
      </div>
    );
  };

  return (
    <div className="choose-car-screen">
      <div
        onClick={() => dispatch(setToken(""))}
        className="d-flex align-items-center pt-1 ps-2"
      >
        <div>{BackIcon}</div>
        <span className="ms-2" style={styles.backTxt}>
          Çıkış
        </span>
      </div>
      {showAlert && (
        <div className="select-car-alert">
          <div className="select-car-alert-container">
            <div>
              <span className="text-center d-flex fw-semibold">
                Bu aracı seçmek istediğinize emin misiniz?
              </span>
            </div>
            <div className="d-flex justify-content-around mt-4">
              <button
                type="button"
                className="btn btn-outline-danger col-4"
                onClick={() => setShowAlert(false)}
              >
                İptal
              </button>
              <button
                type="button"
                className="btn btn-outline-primary col-4"
                onClick={() => {
                  setShowAlert(false);
                  selectCar();
                }}
              >
                Tamam
              </button>
            </div>
          </div>
        </div>
      )}
      {showEmptyAlert && (
        <div className="select-car-alert">
          <div className="select-car-alert-container">
            <div>
              <span className="text-center d-flex fw-semibold">
                Sizin için tanımlanmış bir araç bulunamadı. Lütfen yöneticiyle
                irtibata geçiniz.
              </span>
            </div>
            <div className="d-flex justify-content-around mt-3">
              <button
                type="button"
                className="btn btn-outline-danger col-4"
                onClick={() => {
                  setShowEmptyAlert(false);
                  dispatch(setToken(""));
                }}
              >
                Tamam
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className="fw-2 fs-5"
        style={{
          height: "7vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span>Araç Seçiniz</span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <input
          className="col-10 car-search-input ps-2"
          placeholder="Araç ara..."
          value={search}
          onChange={(e) => searchFilter(e.target.value)}
        ></input>
      </div>
      <div className="cars-flat-list pt-3">
        <FlatList
          list={carList}
          renderItem={<RenderCars />}
          renderWhenEmpty={() => (
            <div className="d-flex justify-content-center pt-5">
              <span>Size atanmış bir araç bulunmamaktadır.</span>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default PoolChooseCarScreen;

const styles = {
  backTxt: {
    fontSize: "5vw",
  },
};
