import axios from "axios";
import { SAW_URL } from "constants";
import { setToken } from "@redux";
import store from "@redux/store";

export async function postRequestSaw(URL, data) {
  const reduxValues = store.getState();
  const token = reduxValues.persisted.token;
  const postAxios = axios.create();
  postAxios.defaults.withCredentials = true;
  postAxios.defaults.timeout = 10000; // 10 sn
  postAxios.defaults.baseURL = SAW_URL;
  postAxios.defaults.headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  postAxios.interceptors.response.use(
    function (response) {
      console.log("postAxiosSaw successed");
      return response;
    },
    function (error) {
      if (error.response && error.response.status === 401) {
        console.log("postAxiosSaw failed");
      }
      return Promise.reject(error);
    }
  );
  return postAxios.post(`/${URL}`, data).then((response) => response.data);
}
