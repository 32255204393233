import React, { useEffect, useState } from "react";
import "./PickUpScreen.css";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { driverIcon } from "assets";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { getTransferDetail, postRequest } from "services";
import { END_POINTS } from "constants";
import moment from "moment";
import { Timer } from "components";
import { setDriverLocation, setPickUpTime } from "@redux";
import { Alert } from "@mui/material";
import { getDistance } from "geolib";
import { ref, update } from "firebase/database";
import { db } from "services/firebaseConfig";
import { Toaster } from "react-hot-toast";
import { wh, ww } from "helpers";

function PickUpScreen() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const transferDetail = useSelector((state) => state.persisted.transferDetail);
  const transferDetailTime = useSelector(
    (state) => state.persisted.transferDetailTime
  );
  const [statusMessage, setStatusMessage] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const driverLocation = useSelector((state) => state.persisted.driverLocation);
  const [openTimer, setOpenTimer] = useState(false);
  const [remainingTimer, setRemainingTimer] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const latLng = `${transferDetail.start_latitude},${transferDetail.start_longitude}`;
  function openMap() {
    window.open("https://maps.google.com?q=" + latLng);
  }

  useEffect(() => {
    getWatchPosition();
  }, []);

  function getWatchPosition() {
    console.log("watch run");
    navigator.geolocation.watchPosition((position) => {
      console.log(
        "watch position: ",
        "latitude: ",
        position.coords.latitude,
        "longitude: ",
        position.coords.longitude
      );
      if (position.coords.latitude !== 0 && position.coords.longitude !== 0) {
        dispatch(
          setDriverLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      }
      update(ref(db, "pudriver/drivers/" + driverInfo.id), {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        bearing: position.coords.heading,
        speed: position.coords.speed,
        time: position.timestamp,
        accuracy: position.coords.accuracy,
        plate: driverInfo.plate,
        update_time: moment().locale("tr").format("YYYY-MM-DD HH:mm:ss"),
      });
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }

  async function goCallPassanger() {
    setSpinner(true);
    if (transferDetail.driver_status_id == 3) {
      getWatchPosition();
      const clickMapStep = moment();
      const duration = moment
        .utc(
          moment(clickMapStep, "DD/MM/YYYY HH:mm:ss").diff(
            moment(transferDetailTime, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      const [hour, minute, second] = duration.split(":");
      const durationSeconds = +hour * 60 * 60 + +minute * 60 + +second;
      const remainingSeconds = 60 - durationSeconds;
      setRemainingTimer(remainingSeconds);
      var data = qs.stringify({
        id: transferDetail.id,
        status: transferDetail.driver_status_id,
        latitude: driverLocation.latitude,
        longitude: driverLocation.longitude,
      });
      if (durationSeconds < 60 && remainingSeconds > 0) {
        setSpinner(false);
        setOpenTimer(true);
      } else {
        const calculatedDistance = getDistance(
          {
            latitude: driverLocation.latitude,
            longitude: driverLocation.longitude,
          },
          {
            latitude: transferDetail.start_latitude,
            longitude: transferDetail.start_longitude,
          }
        );
        if (calculatedDistance <= transferDetail.radius) {
          try {
            const newTransferStatus = await postRequest(
              END_POINTS.CHANGE_TRANSFER_STATUS,
              data
            );
            if (newTransferStatus.status_code == 1) {
              console.log("PickUpScreen - goCallPassanger function - başarılı");
              const time = moment();
              dispatch(setPickUpTime(time));
              getTransferDetail(transferDetail.id);
              setSpinner(false);
              navigate("/Yolcu-Alindi-Veya-Bulunamadi");
            } else {
              setStatusCode(0);
              setStatusMessage(newTransferStatus.status_message);
              setTimeout(() => {
                setStatusCode(null);
                setSpinner(false);
              }, 2000);
              console.log(
                "PickUpScreen - goCallPassanger function - başarısız"
              );
            }
          } catch (error) {
            console.log(
              "PickUpScreen - goCallPassanger function - error: ",
              error
            );
          }
        } else {
          console.log(calculatedDistance);
          setStatusCode(0);
          setStatusMessage("Yolcu alış noktasına henüz ulaşmadınız.");
          setTimeout(() => {
            setSpinner(false);
            setStatusCode(null);
          }, 2000);
        }
      }
    } else if (transferDetail.driver_status_id > 3) {
      navigate("/Yolcu-Alindi-Veya-Bulunamadi");
      setSpinner(false);
    } else {
      console.log(
        "PickUpScreen - goCallPassanger function - transfer adımı hatası"
      );
      setSpinner(false);
    }
  }

  return (
    <>
      {openTimer ? (
        <Timer remainingTimer={remainingTimer} setOpenTimer={setOpenTimer} />
      ) : (
        <></>
      )}
      <div className="screen container-fluid">
        <div className="black-header">
          <Toaster />
          <div className="d-flex flex-column justify-content-end align-items-center">
            <span className="black-header-txt">Alış Yeri</span>
            <div className="white-underline"></div>
          </div>
          <div className="d-flex flex-column justify-content-end align-items-center">
            <span className="black-header-txt">Bırakış Yeri</span>
            <div className="black-underline"></div>
          </div>
        </div>
        <div className="address-container">
          <div className="d-flex align-items-center justify-content-around">
            <span className="letter-txt">A</span>
            <span className="address-txt">{transferDetail.start_address}</span>
          </div>
          <div onClick={openMap}>
            <button className="btn btn-primary">Haritada Aç</button>
          </div>
        </div>
        {statusCode == 0 ? (
          <Alert className="alert-class" severity="error">
            {statusMessage}
          </Alert>
        ) : (
          <></>
        )}
        <LoadScript googleMapsApiKey="AIzaSyCdbCzrvHs61gFPEkbVYUUtCJa1LHeXh0M">
          <GoogleMap
            mapContainerClassName="map d-flex justify-content-center align-items-center"
            center={{
              lat: driverLocation.latitude,
              lng: driverLocation.longitude,
            }}
            zoom={10}
          >
            <Marker
              position={{
                lat: parseFloat(driverLocation.latitude),
                lng: parseFloat(driverLocation.longitude),
              }}
              icon={driverIcon}
            />
            <Marker
              position={{
                lat: parseFloat(transferDetail.start_latitude),
                lng: parseFloat(transferDetail.start_longitude),
              }}
            />
            <div className="pick-up-btn-div">
              <button
                type="button"
                className="btn btn-primary pick-up-btn d-flex align-items-center justify-content-center"
                onClick={goCallPassanger}
              >
                Yolcu Alış Adresinde Bekliyorum
                {spinner && (
                  <div className="clearfix">
                    <div
                      className="spinner-border float-end ms-1"
                      style={{ width: "4.5vw", height: "4.5vw" }}
                      role="status"
                    ></div>
                  </div>
                )}
              </button>
            </div>
          </GoogleMap>
        </LoadScript>
      </div>
    </>
  );
}

export default PickUpScreen;
