import React, { useEffect, useState } from "react";
import "./CallPassangerScreen.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { getTransferDetail, postRequest } from "services";
import { END_POINTS } from "constants";
import { setCallPassengerTime, setDriverLocation } from "@redux";
import { Timer } from "components";
import moment from "moment";
import { Alert } from "@mui/material";
import { ref, update } from "firebase/database";
import { db } from "services/firebaseConfig";
import { Toaster } from "react-hot-toast";

function CallPassangerScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const driverInfo = useSelector((state) => state.persisted.driverInfo);
  const transferDetail = useSelector((state) => state.persisted.transferDetail);
  const driverLocation = useSelector((state) => state.persisted.driverLocation);
  const pickUpTime = useSelector((state) => state.persisted.pickUpTime);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [openTimer, setOpenTimer] = useState(false);
  const [remainingTimer, setRemainingTimer] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [passanger, setPassanger] = useState(false);
  const [noPassanger, setNoPassanger] = useState(false);

  const element = (
    <FontAwesomeIcon className="phone-icon" icon={faPhone} color="green" />
  );

  useEffect(() => {
    getWatchPosition();
  }, []);

  function getWatchPosition() {
    console.log("watch run");
    navigator.geolocation.watchPosition((position) => {
      console.log(
        "watch position: ",
        "latitude: ",
        position.coords.latitude,
        "longitude: ",
        position.coords.longitude
      );
      if (position.coords.latitude !== 0 && position.coords.longitude !== 0) {
        dispatch(
          setDriverLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      }
      update(ref(db, "pudriver/drivers/" + driverInfo.id), {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        bearing: position.coords.heading,
        speed: position.coords.speed,
        time: position.timestamp,
        accuracy: position.coords.accuracy,
        plate: driverInfo.plate,
        update_time: moment().locale("tr").format("YYYY-MM-DD HH:mm:ss"),
      });
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }

  async function goDropOffPoint() {
    setPassanger(true);
    setNoPassanger(false);
    setSpinner(true);
    if (transferDetail.driver_status_id == 4) {
      const clickMapStep = moment();
      const duration = moment
        .utc(
          moment(clickMapStep, "DD/MM/YYYY HH:mm:ss").diff(
            moment(pickUpTime, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      const [hour, minute, second] = duration.split(":");
      const durationSeconds = +hour * 60 * 60 + +minute * 60 + +second;
      const remainingSeconds = 60 - durationSeconds;
      setRemainingTimer(remainingSeconds);
      var data = qs.stringify({
        id: transferDetail.id,
        status: transferDetail.driver_status_id,
        latitude: driverLocation.latitude,
        longitude: driverLocation.longitude,
      });
      if (durationSeconds < 60 && remainingSeconds > 0) {
        setSpinner(false);
        setOpenTimer(true);
      } else {
        try {
          const newTransferStatus = await postRequest(
            END_POINTS.CHANGE_TRANSFER_STATUS,
            data
          );
          if (newTransferStatus.status_code == 1) {
            console.log(
              "CallPassangerScreen - goDropOffPoint function - başarılı"
            );
            const time = moment();
            dispatch(setCallPassengerTime(time));
            getTransferDetail(transferDetail.id);
            setSpinner(false);
            navigate("/Yolcu-Birakis-Noktasi");
          } else {
            setStatusCode(0);
            setStatusMessage(newTransferStatus.status_message);
            setTimeout(() => {
              setSpinner(false);
              setStatusCode(null);
            }, 2000);
            console.log(
              "CallPassangerScreen - goDropOffPoint function - başarısız"
            );
          }
        } catch (error) {
          setSpinner(false);
          console.log(
            "CallPassangerScreen - goDropOffPoint function - error: ",
            error
          );
        }
      }
    } else if (transferDetail.driver_status_id > 4) {
      if (transferDetail.driver_status_id == 8) {
        navigate("/Transfer-Tamamlandi");
        setSpinner(false);
      } else {
        navigate("/Yolcu-Birakis-Noktasi");
        setSpinner(false);
      }
    } else {
      setSpinner(false);
      console.log(
        "CallPassangerScreen - goDropOffPoint function - transfer adımı hatası"
      );
    }
  }
  async function goNoShow() {
    setNoPassanger(true);
    setPassanger(false);
    setSpinner(true);
    if (transferDetail.driver_status_id == 4) {
      const clickMapStep = moment();
      const duration = moment
        .utc(
          moment(clickMapStep, "DD/MM/YYYY HH:mm:ss").diff(
            moment(pickUpTime, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      const [hour, minute, second] = duration.split(":");
      const durationSeconds = +hour * 60 * 60 + +minute * 60 + +second;
      const remainingSeconds = 60 - durationSeconds;
      setRemainingTimer(remainingSeconds);
      var data = qs.stringify({
        id: transferDetail.id,
        status: 8,
        latitude: driverLocation.latitude,
        longitude: driverLocation.longitude,
      });
      if (durationSeconds < 60 && remainingSeconds > 0) {
        setOpenTimer(true);
        setSpinner(false);
      } else {
        try {
          const newTransferStatus = await postRequest(
            END_POINTS.CHANGE_TRANSFER_STATUS,
            data
          );
          if (newTransferStatus.status_code == 1) {
            console.log("CallPassangerScreen - goNoShow function - başarılı");
            getTransferDetail(transferDetail.id);
            setSpinner(false);
            navigate("/Transfer-Tamamlandi");
          } else {
            setStatusCode(0);
            setStatusMessage(newTransferStatus.status_message);
            setTimeout(() => {
              setSpinner(false);
              setStatusCode(null);
            }, 2000);
            console.log("CallPassangerScreen - goNoShow function - başarısız");
          }
        } catch (error) {
          setSpinner(false);
          console.log(
            "CallPassangerScreen - goNoShow function - error: ",
            error
          );
        }
      }
    } else if (transferDetail.driver_status_id > 4) {
      if (transferDetail.driver_status_id == 5) {
        setSpinner(false);
        navigate("/Yolcu-Birakis-Noktasi");
      } else {
        setSpinner(false);
        navigate("/Transfer-Tamamlandi");
      }
    } else {
      setSpinner(false);
      console.log(
        "CallPassangerScreen - goNoShow function - transfer adımı hatası"
      );
    }
  }

  return (
    <>
      {openTimer ? (
        <Timer remainingTimer={remainingTimer} setOpenTimer={setOpenTimer} />
      ) : (
        <></>
      )}
      <div className="screen container-fluid">
        <div className="black-header">
          <Toaster />
          <div className="d-flex flex-column justify-content-end align-items-center">
            <span className="black-header-txt">Alış Yeri</span>
            <div className="white-underline"></div>
          </div>
          <div className="d-flex flex-column justify-content-end align-items-center">
            <span className="black-header-txt">Bırakış Yeri</span>
            <div className="black-underline"></div>
          </div>
        </div>
        {statusCode == 0 ? (
          <Alert className="alert-class" severity="error">
            {statusMessage}
          </Alert>
        ) : (
          <></>
        )}
        <div className="justify-content-center d-flex flex-column align-items-center">
          <div className="info-txt-div">
            <span className="info-txt">Yolcu Bekleniyor...</span>
          </div>
          <div className="pass-name-div">
            <span className="pass-name">{transferDetail.passenger_name}</span>
          </div>
          <div className="phone-icon">{element}</div>
          <div className="">
            <button
              type="button"
              className="btn btn-primary take-pass-btn d-flex align-items-center justify-content-center"
              onClick={goDropOffPoint}
            >
              Yolcu Alındı
              {spinner && passanger && (
                <div className="clearfix">
                  <div
                    className="spinner-border float-end ms-1"
                    style={{ width: "4.5vw", height: "4.5vw" }}
                    role="status"
                  ></div>
                </div>
              )}
            </button>
          </div>
          <div className="">
            <button
              type="button"
              className="btn no-pass-btn d-flex align-items-center justify-content-center"
              onClick={goNoShow}
            >
              Yolcu Bulunamadı
              {spinner && noPassanger && (
                <div className="clearfix">
                  <div
                    className="spinner-border float-end ms-1"
                    style={{ width: "4.5vw", height: "4.5vw" }}
                    role="status"
                  ></div>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CallPassangerScreen;
