import {
  SET_TOKEN,
  SET_POOL,
  SET_GROUP,
  SET_TYPE,
  SET_HAVE_CAR,
  SET_DRIVERINFO,
  SET_TRANSFER_ID,
  SET_TRANSFERDETAIL,
  TRANSFER_DETAIL_TIME,
  PICK_UP_TIME,
  CALL_PASSENGER_TIME,
  AUTH_INFO,
  FCM_TOKEN,
  DRIVER_LOCATION,
} from "./persistedTypes";

const initialState = {
  token: "",
  pool: null,
  group: null,
  type: null,
  haveCar: false,
  driverInfo: [],
  transferId: "",
  transferDetail: [],
  transferDetailTime: "",
  pickUpTime: "",
  callPassengerTime: "",
  authInfo: [],
  fcmToken: "",
  driverLocation: {
    latitude: 0,
    longitude: 0,
  },
};

const persistedReducers = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case SET_POOL:
      return { ...state, pool: action.payload };
    case SET_GROUP:
      return { ...state, group: action.payload };
    case SET_TYPE:
      return { ...state, type: action.payload };
    case SET_HAVE_CAR:
      return { ...state, haveCar: action.payload };
    case SET_DRIVERINFO:
      return { ...state, driverInfo: action.payload };
    case SET_TRANSFER_ID:
      return { ...state, transferId: action.payload };
    case SET_TRANSFERDETAIL:
      return { ...state, transferDetail: action.payload };
    case TRANSFER_DETAIL_TIME:
      return { ...state, transferDetailTime: action.payload };
    case PICK_UP_TIME:
      return { ...state, pickUpTime: action.payload };
    case CALL_PASSENGER_TIME:
      return { ...state, callPassengerTime: action.payload };
    case AUTH_INFO:
      return { ...state, authInfo: action.payload };
    case FCM_TOKEN:
      return { ...state, fcmToken: action.payload };
    case DRIVER_LOCATION:
      return { ...state, driverLocation: action.payload };
  }
  return state;
};

export default persistedReducers;
